import { apiSlice } from '../api/api-slice';
import serializeObjectToURI from 'utils/serializer';
import { CasesSearchResponse, CasesTypeRequest } from 'types/cases/cases';

export const caseApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        searchCases: builder.query<CasesSearchResponse, CasesTypeRequest>({
            query: (casesTypeRequest: CasesTypeRequest) => ({
                url: serializeObjectToURI(casesTypeRequest, '/public/cases?'),
                method: 'GET',
            }),
        }),
    }),
});

export const { useLazySearchCasesQuery } = caseApi;
