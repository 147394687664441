import pxToRem from 'assets/theme/function/px-to-rem';

const dialogActions = {
    styleOverrides: {
        root: {
            paddingBottom: pxToRem(40),
            paddingLeft: pxToRem(50),
            paddingRight: pxToRem(50),
        },
    },
};

export default dialogActions;
