import borders from 'assets/theme/base/borders';
import typography from 'assets/theme/base/typography';
import colors from 'assets/theme/base/colors';
import pxToRem from 'assets/theme/function/px-to-rem';

const { white, grey, text, inputBorderColor, error } = colors;
const { borderRadius } = borders;
const { size } = typography;

const inputOutlined = {
    styleOverrides: {
        root: {
            backgroundColor: white.main,
            borderRadius: borderRadius.md,
            height: pxToRem(40),
            color: text.secondary,
            fontWeight: 400,
            lineHeight: '20px',

            '&:hover, &.Mui-focused': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: inputBorderColor,
                },
            },
            '&.MuiInputBase-input': {
                padding: '50px',
            },

            '&.Mui-error': {
                '&:hover, &.Mui-focused': {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: error.secondary,
                    },
                },
            },
        },

        notchedOutline: {
            borderColor: inputBorderColor,
        },

        input: {
            color: text.primary,
            //padding: '8px, 16px',
            //backgroundColor: white.main,
            //WebkitTextFillColor: white.main,
            WebkitBackgroundClip: 'text',
            //WebkitBoxShadow: 'none',
        },

        inputSizeSmall: {
            fontSize: size.xs,
            // padding: pxToRem(10),
        },

        multiline: {
            color: grey[700],
            //padding: 0,
        },
    },
};

export default inputOutlined;
