import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/index';
import { UserState } from 'types/user/user-state';

const initialState: UserState = {
    user: localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user') as string)
        : null,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (
            state: UserState,
            action: PayloadAction<UserState['user']>,
        ) => {
            state.user = action.payload;
        },
        logOutUser: (state: UserState) => {
            state.user = null;
        },
    },
});

export const { setUser, logOutUser } = userSlice.actions;

export default userSlice.reducer;

export const selectCurrentUser = (state: RootState) => state.user.user;
