import React from 'react';
import { Navigate } from 'react-router-dom';
import { RouteParams } from 'types/routes/route-params';
import PublicLayout from 'layouts/public';
import ProtectedLayout from 'layouts/protected';
import { useAppSelector } from 'hooks/redux';
import { selectCurrentUser } from 'store/slices/user/user-slice';

const RouteElement: React.FC<RouteParams> = (routeProp: RouteParams) => {
    const { element: Component, ...routeParams } = routeProp;

    const currentUser = useAppSelector(selectCurrentUser);

    return (
        <>
            {currentUser && routeParams?.rules?.private ? (
                <ProtectedLayout
                    pageTitle={routeParams.title}
                    logo={routeParams.logo}
                    key={routeParams.name}
                >
                    <Component
                        key={routeParams.name}
                        type
                        props={routeParams.props}
                    />
                </ProtectedLayout>
            ) : (
                <>
                    {routeParams?.rules?.private ? (
                        <Navigate to="/" replace />
                    ) : (
                        <PublicLayout
                            pageTitle={routeParams.title}
                            key={routeParams.name}
                        >
                            <Component
                                key={routeParams.name}
                                type
                                props={routeParams.props}
                            />
                        </PublicLayout>
                    )}
                </>
            )}
        </>
    );
};

export default RouteElement;
