import typography from 'assets/theme/base/typography';
import colors from 'assets/theme/base/colors';

const { fontWeightRegular, size } = typography;

const dayCalendar = {
    styleOverrides: {
        weekDayLabel: {
            color: colors.text.primary,
            fontSize: size.xs,
            fontWeight: fontWeightRegular,
        },
    },
};

export default dayCalendar;
