import pxToRem from 'assets/theme/function/px-to-rem';

const svgIcon = {
    defaultProps: {
        //fontSize: 'inherit',
    },

    styleOverrides: {
        fontSizeInherit: {
            fontSize: 'inherit',
        },

        fontSizeSmall: {
            fontSize: `${pxToRem(20)}`,
        },

        fontSizeLarge: {
            fontSize: `${pxToRem(36)}`,
        },
    },
};

export default svgIcon;
