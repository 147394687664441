import colors from 'assets/theme/base/colors';
import pxToRem from 'assets/theme/function/px-to-rem';

const tableSortLable = {
    styleOverrides: {
        root: {
            color: colors.grey['700'],
            opacity: 1,
            '.MuiTableSortLabel-icon': {
                opacity: 0.2,
                margin: 0,
                fontSize: pxToRem(17),
            },
            /*
            '&:hover': {
                color: colors.error.secondary,

                '&& $icon': {
                    opacity: 1,
                    color: colors.error.secondary,
                },
            },
            '&$active': {
                color: colors.warning.secondary,

                '&& $icon': {
                    opacity: 1,
                    color: colors.grey['700'],
                },
            },*/
        },
    },
};
export default tableSortLable;
