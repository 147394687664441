import {
    BaseQueryApi,
    BaseQueryFn,
    FetchArgs,
    fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import {
    BaseQueryExtraOptions,
    QueryReturnValue,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { API_ENDPOINT_URL } from 'constant';
import { UserResponse } from 'types/user/user';
import { CustomError } from 'types/api-response/custom-error';
import { logOutUser } from '../user/user-slice';

const baseQuery = fetchBaseQuery({
    baseUrl: API_ENDPOINT_URL,
    prepareHeaders: (headers: Headers, {}) => {
        const token = localStorage.getItem('access_token');
        if (token) {
            headers.set('authorization', `Bearer ${token}`);
        }
        /*headers.set('XDEBUG_SESSION_START', `1`);
        headers.set('Access-Control-Allow-Origin', `*`);*/

        return headers;
    },
    timeout: 7000,
}) as BaseQueryFn<string | FetchArgs, unknown, CustomError>;

const baseQueryWithoutToken = fetchBaseQuery({
    baseUrl: API_ENDPOINT_URL,
    timeout: 7000,
}) as BaseQueryFn<string | FetchArgs, unknown, CustomError>;

export const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    CustomError
> = async (
    args: string | FetchArgs,
    api: BaseQueryApi,
    extraOptions: BaseQueryExtraOptions<BaseQueryFn>,
) => {
    let result: QueryReturnValue<unknown, CustomError, object> =
        (await baseQuery(args, api, extraOptions)) as QueryReturnValue<
            unknown,
            CustomError,
            object
        >;

    if (result.error?.status === 401) {
        const token = localStorage.getItem('refresh_token');

        const refreshResult = (await baseQueryWithoutToken(
            {
                url: '/auth/token/refresh',
                method: 'POST',
                body: { refresh_token: token },
            },
            api,
            extraOptions,
        )) as QueryReturnValue;

        if (refreshResult?.data) {
            const refreshResultResponse: UserResponse =
                refreshResult?.data as UserResponse;

            localStorage.setItem('access_token', refreshResultResponse.token);
            localStorage.setItem(
                'refresh_token',
                refreshResultResponse.refresh_token,
            );

            result = (await baseQuery(
                args,
                api,
                extraOptions,
            )) as QueryReturnValue<unknown, CustomError, object>;
        } else {
            api.dispatch(logOutUser());

            localStorage.removeItem('user');
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
        }
    }
    return result;
};
