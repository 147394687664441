import * as React from 'react';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import pxToRem from 'assets/theme/function/px-to-rem';
import CurrentUserMenu from 'layouts/protected/personal-nu';
import colors from 'assets/theme/base/colors';
import TableTransactionHistory from 'pages/transaction-history/table-transaction-history';

const TransactionHistory: React.FC = () => {
    const [page, setPage] = useState<number>(1);
    const handleFilterData = (currentPage = 0) => {
        setPage(currentPage + 1);
        page;
    };
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const money = 1200;
    return (
        <>
            <Grid container spacing={0}>
                <Grid item xs={12} md={1.8} lg={1.8} bgcolor={colors.info.main}>
                    <Box bgcolor={'transparent'}>
                        <CurrentUserMenu />
                    </Box>
                </Grid>
                <Grid item xs={12} md={10.2} lg={10.2}>
                    <Box
                        display={'flex'}
                        ml={pxToRem(32)}
                        mr={
                            isMobile
                                ? pxToRem(24)
                                : isTablet
                                  ? pxToRem(35)
                                  : pxToRem(72)
                        }
                        mt={pxToRem(32)}
                        px={pxToRem(16)}
                        py={pxToRem(8)}
                        justifyContent={'space-between'}
                        bgcolor={colors.info.secondary}
                    >
                        <Typography variant={'h5'}>
                            ԸՆԴՀԱՆՈՒՐ ԴՐԱՄԱԿԱՆ ՇԱՐժ
                        </Typography>
                        <Box display={'flex'} alignItems={'center'}>
                            <Typography
                                variant={'h6'}
                                mr={pxToRem(10)}
                                color={colors.success.main}
                            >
                                Մուտք:{money} AMD
                            </Typography>
                            <Typography
                                variant={'h6'}
                                color={colors.error.main}
                            >
                                Ելք:{money} AMD
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        ml={pxToRem(32)}
                        mt={pxToRem(32)}
                        mr={
                            isMobile
                                ? pxToRem(24)
                                : isTablet
                                  ? pxToRem(35)
                                  : pxToRem(72)
                        }
                    >
                        <TableTransactionHistory
                            handleFilterData={handleFilterData}
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};
export default TransactionHistory;
