import typography from 'assets/theme/base/typography';
import colors from 'assets/theme/base/colors';

const { size } = typography;
const { text } = colors;

const dialogContentText = {
    styleOverrides: {
        root: {
            fontSize: size.md,
            color: text.primary,
        },
    },
};

export default dialogContentText;
