import { apiSlice } from 'store/slices/api/api-slice';
import {
    PersonalInfo,
    PersonalInfoRequest,
} from 'types/personal-info/personal-info';

const personalInfoApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        personalInfo: builder.query<PersonalInfo, void>({
            query: () => ({
                url: '/app/user-details?',
                method: 'GET',
            }),
        }),
        personalDetailConfirm: builder.mutation({
            query: (personalInfoRequest: PersonalInfoRequest) => ({
                url: '/app/update-user-details?',
                method: 'PUT',
                body: { ...personalInfoRequest },
            }),
        }),
    }),
});

export const { usePersonalInfoQuery, usePersonalDetailConfirmMutation } =
    personalInfoApi;
