import * as React from 'react';
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from '@mui/material';
import pxToRem from 'assets/theme/function/px-to-rem';
import colors from 'assets/theme/base/colors';
import { useEffect, useState } from 'react';
import { UserRegisterRequest } from 'types/user/user';
import { useAppDispatch } from 'hooks/redux';
import { useRegisterMutation } from 'store/slices/user/user-auth-api';
import { openSnackbar } from 'store/slices/snackbar/snackbar-slice';
import { ReactComponent as Eye } from 'assets/images/group.svg';
import { ReactComponent as NounEye } from 'assets/images/noun.svg';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ReactComponent as CalendarMonthIcon } from 'assets/images/calendar.svg';
import dayjs, { Dayjs } from 'dayjs';
import { FieldChangeHandlerContext } from '@mui/x-date-pickers/internals';

interface Props {
    openRegisterDialog: boolean;
    setOpenRegisterDialog: React.Dispatch<React.SetStateAction<boolean>>;
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
    setOpenConfirmEmailDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

const genderList = [
    { label: 'Իգական', value: 'female' },
    { label: 'Արական', value: 'male' },
];

const Register: React.FC<Props> = (props: Props) => {
    const {
        openRegisterDialog,
        setOpenRegisterDialog,
        setOpenDialog,
        setOpenConfirmEmailDialog,
    } = props;
    const [register, setRegister] = useState<UserRegisterRequest>({
        email: '',
        password: '',
        passwordConfirm: '',
        birthDate: '',
        lastName: '',
        firstName: '',
    });
    const dispatch = useAppDispatch();
    const [showPass, setShowPass] = useState<boolean>(false);
    const [showConfirmPass, setShowConfirmPass] = useState<boolean>(false);
    const [startDateError, setStartDateError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const [registerApi, { isError, error, isSuccess }] = useRegisterMutation();

    let isValid = true;
    let errorText = '';
    const validatePlantingMaterialInfo = (): boolean => {
        if (register.email == '' || register.email == 'Invalid Date') {
            isValid = false;
            errorText = 'Դուք ունեք դատարկ դաշտեր';
        }
        if (register.password == '' || register.password == 'Invalid Date') {
            isValid = false;
            errorText = 'Դուք ունեք դատարկ դաշտեր';
        }
        if (
            register.passwordConfirm == '' ||
            register.passwordConfirm == 'Invalid Date'
        ) {
            isValid = false;
            errorText = 'Դուք ունեք դատարկ դաշտեր';
        }

        if (!isValid) {
            dispatch(
                openSnackbar({
                    message: errorText,
                    severity: 'error',
                    open: true,
                    position: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                }),
            );
        }

        return isValid;
    };

    const handelRegister = async () => {
        if (validatePlantingMaterialInfo()) {
            registerApi(register);
        } else {
            dispatch(
                openSnackbar({
                    message: errorText,
                    severity: 'error',
                    open: true,
                    position: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                }),
            );
        }
    };

    useEffect(() => {
        if (isSuccess) {
            setOpenRegisterDialog(false);
            setOpenConfirmEmailDialog(true);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            setOpenRegisterDialog(true);
            setOpenConfirmEmailDialog(false);
        }
    }, [isError]);
    useEffect(() => {
        if (error && 'data' in error) {
            setErrorMessage(error?.data.message);
        }
    }, [isError]);

    return (
        <>
            <Dialog
                open={openRegisterDialog}
                onClose={() => {
                    setOpenRegisterDialog(false);
                }}
                PaperProps={{
                    sx: {
                        width: pxToRem(540),
                    },
                }}
            >
                <Typography color={colors.error.main} textAlign={'center'}>
                    {errorMessage}
                </Typography>
                <DialogTitle textAlign={'center'} variant={'h3'}>
                    Գրանցվել
                </DialogTitle>
                <DialogContent>
                    <Box paddingTop={pxToRem(20)}>
                        <TextField
                            fullWidth
                            required
                            label="Անուն"
                            type="name"
                            name="lastName"
                            aria-describedby="nameTextHelper"
                            inputProps={{
                                'aria-label': 'lastName',
                            }}
                            onChange={(e) => {
                                const { name, value } = e.target;
                                setRegister((prevState) => ({
                                    ...prevState,
                                    [name]: value,
                                }));
                            }}
                            error={isError && register.lastName == ''}
                            helperText={
                                error && register.lastName == ''
                                    ? 'Անվան դաշտը դատարկ է'
                                    : null
                            }
                        />

                        <TextField
                            sx={{ mt: pxToRem(10) }}
                            fullWidth
                            required
                            label="Ազգանուն"
                            type="surName"
                            name="firstName"
                            aria-describedby="nameTextHelper"
                            inputProps={{
                                'aria-label': 'firstName',
                            }}
                            onChange={(e) => {
                                const { name, value } = e.target;
                                setRegister((prevState) => ({
                                    ...prevState,
                                    [name]: value,
                                }));
                            }}
                            error={isError && register.firstName == ''}
                            helperText={
                                error && register.firstName == ''
                                    ? 'Ազգանուն դաշտը դատարկ է'
                                    : null
                            }
                        />
                        <Box
                            display={'flex'}
                            justifyContent={'space-around'}
                            alignItems={'center'}
                            marginTop={pxToRem(10)}
                        >
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale={'hy-am'}
                            >
                                <DatePicker
                                    label={'Ծննդյան ամսաթիվ'}
                                    sx={{ width: '100%' }}
                                    disableFuture
                                    showDaysOutsideCurrentMonth
                                    views={['year', 'month', 'day']}
                                    format="DD MMMM YYYY"
                                    value={dayjs(register.birthDate)}
                                    //inputRef={startDateInputRef}
                                    onChange={(
                                        newValue: any,
                                        context: FieldChangeHandlerContext<any>,
                                    ) => {
                                        newValue &&
                                        context.validationError !==
                                            'invalidDate'
                                            ? setStartDateError(false)
                                            : setStartDateError(true);
                                        setRegister((prevState: any) => {
                                            return {
                                                ...prevState,
                                                birthDate:
                                                    dayjs(newValue).format(
                                                        'YYYY-MM-DD',
                                                    ),
                                            };
                                        });
                                    }}
                                    slots={{
                                        openPickerIcon: CalendarMonthIcon,
                                    }}
                                    slotProps={{
                                        textField: {
                                            required: true,
                                            InputLabelProps: {
                                                margin: 'dense',
                                            },
                                            error: startDateError,
                                        },
                                        actionBar: ({ wrapperVariant }) => ({
                                            actions:
                                                wrapperVariant === 'mobile'
                                                    ? ['cancel', 'accept']
                                                    : [],
                                        }),
                                    }}
                                    dayOfWeekFormatter={(
                                        _day: string,
                                        date: Dayjs,
                                    ) => dayjs(date).format('dd').toUpperCase()}
                                />
                            </LocalizationProvider>
                            <Autocomplete
                                sx={{ ml: pxToRem(5) }}
                                fullWidth
                                disablePortal
                                id="combo-box-demo"
                                options={genderList}
                                renderInput={(params) => (
                                    <TextField {...params} label="Սեռ" />
                                )}
                                onChange={(e, value) => {
                                    setRegister((prevState) => ({
                                        ...prevState,
                                        gender: value ? value.value : '',
                                    }));
                                }}
                            />
                        </Box>

                        <TextField
                            sx={{ mt: pxToRem(10) }}
                            fullWidth
                            label="Հերախոսահամար"
                            type="tel"
                            name="phone"
                            aria-describedby="phoneTextHelper"
                            inputProps={{
                                'aria-label': 'phone',
                            }}
                            onChange={(e) => {
                                const { name, value } = e.target;
                                setRegister((prevState) => ({
                                    ...prevState,
                                    [name]: value,
                                }));
                            }}
                        />

                        <TextField
                            sx={{ mt: pxToRem(10) }}
                            fullWidth
                            required
                            label="Էլ. հասցե"
                            type="email"
                            name="email"
                            aria-describedby="emailTextHelper"
                            inputProps={{
                                'aria-label': 'email',
                            }}
                            onChange={(e) => {
                                const { name, value } = e.target;
                                setRegister((prevState) => ({
                                    ...prevState,
                                    [name]: value,
                                }));
                            }}
                            error={isError && register.email == ''}
                            helperText={
                                error && register.email == ''
                                    ? 'Էլ. հասցե դաշտը դատարկ է'
                                    : null
                            }
                        />

                        <TextField
                            sx={{ mt: pxToRem(10) }}
                            fullWidth
                            required
                            label="Գաղտնաբառ"
                            name="password"
                            type={showPass ? 'text' : 'password'}
                            aria-describedby="passwordTextHelper"
                            InputProps={{
                                'aria-label': 'password',
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() =>
                                                setShowPass(
                                                    (current) => !current,
                                                )
                                            }
                                            onMouseDown={(e) =>
                                                e.preventDefault()
                                            }
                                            edge="end"
                                        >
                                            {showPass ? <Eye /> : <NounEye />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(e) => {
                                const { name, value } = e.target;
                                setRegister(
                                    (prevState: UserRegisterRequest) => ({
                                        ...prevState,
                                        [name]: value,
                                    }),
                                );
                            }}
                            error={isError && register.password == ''}
                            helperText={
                                error && register.password == ''
                                    ? 'Դուք ունեք դատարկ դաշտեր'
                                    : null
                            }
                        />
                        <TextField
                            sx={{ mt: pxToRem(10) }}
                            fullWidth
                            required
                            label="Հաստատել Գաղտնաբառը"
                            name="passwordConfirm"
                            type={showConfirmPass ? 'text' : 'password'}
                            aria-describedby="passwordConfirmTextHelper"
                            InputProps={{
                                'aria-label': 'passwordConfirm',
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() =>
                                                setShowConfirmPass(
                                                    (current) => !current,
                                                )
                                            }
                                            onMouseDown={(e) =>
                                                e.preventDefault()
                                            }
                                            edge="end"
                                        >
                                            {showConfirmPass ? (
                                                <Eye />
                                            ) : (
                                                <NounEye />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(e) => {
                                const { name, value } = e.target;
                                setRegister(
                                    (prevState: UserRegisterRequest) => ({
                                        ...prevState,
                                        [name]: value,
                                    }),
                                );
                            }}
                            error={isError && register.passwordConfirm == ''}
                            helperText={
                                error && register.passwordConfirm == ''
                                    ? 'Դուք ունեք դատարկ դաշտեր'
                                    : null
                            }
                        />
                        {/*{emailErrorExists && (
                            <FormHelperText error>
                                Your account is suspended
                            </FormHelperText>
                        )}
                        {emailError && (
                            <FormHelperText error>
                                {emailErrorText}
                            </FormHelperText>
                        )}*/}
                    </Box>
                </DialogContent>
                <DialogActions sx={{ display: 'block' }}>
                    <Box display={'flex'} justifyContent={'end'}>
                        <Button
                            className="logout-confirm-button"
                            variant="contained"
                            color="primary"
                            onClick={handelRegister}
                        >
                            Գրանցվել
                        </Button>
                    </Box>
                    <Box
                        marginTop={pxToRem(20)}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        <Typography variant={'h6'} mr={2}>
                            Անձնական էջի առկայության դեպքում
                        </Typography>
                        <Typography
                            sx={{ cursor: 'pointer' }}
                            variant={'h6'}
                            color={colors.primary.main}
                            onClick={() => {
                                setOpenRegisterDialog(false);
                                setOpenDialog(true);
                            }}
                        >
                            Մուտք
                        </Typography>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default Register;
