import * as React from 'react';
import { useEffect, useState } from 'react';
import {
    Box,
    Dialog,
    IconButton,
    Link,
    Slide,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { ReactComponent as SearchIcon } from 'assets/images/search-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/images/close-icon.svg';
import { TransitionProps } from '@mui/material/transitions';
import pxToRem from 'assets/theme/function/px-to-rem';
import colors from 'assets/theme/base/colors';
import { useLazyCaseFullDataQuery } from 'store/slices/cases/case-full-data';
import {
    Action,
    ActionInput,
    CasesFullDataResponse,
    CourtFullData,
} from 'types/cases/case-full-data';

interface Props {
    caseId: string;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CasesDialog: React.FC<Props> = (props: Props) => {
    const [openCasesDialog, setOpenCasesDialog] = useState(false);
    const [searchFullData, { isSuccess, data }] = useLazyCaseFullDataQuery();
    const theme = useTheme();
    const isBigSize = useMediaQuery(theme.breakpoints.down('xl'));
    const showActDocumentPdf = () => {
        setOpenCasesDialog(true);
    };
    const { caseId } = props;
    const searchFullDataCases = () => {
        searchFullData({
            caseId,
        });
    };
    useEffect(() => {
        if (isSuccess) {
            setOpenCasesDialog(true);
        }
    }, [isSuccess]);

    const handleClose = () => {
        setOpenCasesDialog(false);
    };

    const courtName = (casesFullData: CasesFullDataResponse | undefined) => {
        return casesFullData?.process.map((element: CourtFullData, index) => {
            return (
                <>
                    <Box
                        key={index}
                        bgcolor={colors.text.primary}
                        mt={pxToRem(10)}
                        px={pxToRem(16)}
                        py={pxToRem(4)}
                        mb={pxToRem(4)}
                    >
                        <Typography color={colors.white.main} variant={'h5'}>
                            {element.courtName ? element.courtName : '-------'}
                        </Typography>
                    </Box>
                    {fromActionList(element.actionList)}
                </>
            );
        });
    };

    const fromActionList = (actionList: Action[] | undefined) => {
        return actionList?.map((action: Action, index: number) => (
            <>
                <Box
                    bgcolor={colors.text.secondary}
                    px={pxToRem(16)}
                    py={pxToRem(8)}
                    mt={pxToRem(10)}
                    key={index}
                >
                    <Typography fontWeight={700} variant={'h6'}>
                        {action.title}
                    </Typography>
                </Box>
                {fromActionInputs(action.actionInputs)}
            </>
        ));
    };

    const fromActionInputs = (
        actionInputs: ActionInput[] | undefined,
        counter = 1,
    ) => {
        return actionInputs?.map((input: ActionInput, index: number) => (
            <Box>
                <Box
                    style={{
                        display: Array.isArray(input.value) ? 'block' : 'flex',
                    }}
                >
                    <Box
                        style={{
                            width: Array.isArray(input.value) ? '100%' : '30%',
                            borderBottom: Array.isArray(input.value)
                                ? 'none'
                                : '',
                        }}
                        borderBottom={'1px solid #BEC6EB'}
                    >
                        <Typography
                            pl={pxToRem(10)}
                            variant={'h6'}
                            style={{
                                backgroundColor:
                                    counter === 1
                                        ? colors.warning.main
                                        : colors.info.secondary,
                            }}
                        >
                            {input.title}
                        </Typography>
                    </Box>
                    <Box
                        width={'100%'}
                        bgcolor={colors.white.main}
                        key={index}
                        borderBottom={'1px solid #BEC6EB'}
                    >
                        <Typography
                            style={{
                                paddingLeft: Array.isArray(input.value)
                                    ? pxToRem(0)
                                    : pxToRem(20),
                            }}
                            variant={'h6'}
                        >
                            {typeof input.value === 'string'
                                ? input.value ||
                                  '- - - - - - - - - - - - - - - -'
                                : fromActionInputs(input.value, counter + 1)}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        ));
    };

    const headerDialog = () => {
        return (
            <>
                <Box
                    position={'fixed'}
                    top={pxToRem(0)}
                    left={!isBigSize ? pxToRem(60) : pxToRem(32)}
                    right={!isBigSize ? pxToRem(60) : pxToRem(32)}
                    bgcolor={colors.white.main}
                    mb={pxToRem(20)}
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    <Typography sx={{ m: pxToRem(10) }} variant="h4">
                        Դատական Գործ N: {data?.caseNumber}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        sx={{ mr: pxToRem(35) }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                {courtName(data)}
            </>
        );
    };

    return (
        <>
            <Link
                onClick={() => {
                    showActDocumentPdf();
                    searchFullDataCases();
                }}
            >
                <SearchIcon fontSize="medium" />
            </Link>
            <Dialog
                PaperProps={{
                    sx: {
                        width: pxToRem(1800),
                        maxWidth: pxToRem(1800),
                        height: pxToRem(870),
                    },
                }}
                open={openCasesDialog}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                {headerDialog()}
            </Dialog>
        </>
    );
};
export default CasesDialog;
