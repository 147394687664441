import * as React from 'react';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import TableViewedCases from 'pages/viewed-cases/table-viewed-cases';
import { useState } from 'react';
import pxToRem from 'assets/theme/function/px-to-rem';
import CurrentUserMenu from 'layouts/protected/personal-nu';
import colors from 'assets/theme/base/colors';

const ViewedCases: React.FC = () => {
    const [page, setPage] = useState<number>(1);
    const handleFilterData = (currentPage = 0) => {
        setPage(currentPage + 1);
        page;
    };
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <>
            <Grid container spacing={0}>
                <Grid item xs={12} md={1.8} lg={1.8} bgcolor={colors.info.main}>
                    <Box bgcolor={'transparent'}>
                        <CurrentUserMenu />
                    </Box>
                </Grid>
                <Grid item xs={12} md={10.2} lg={10.2}>
                    <Box
                        ml={pxToRem(32)}
                        mt={pxToRem(32)}
                        mr={
                            isMobile
                                ? pxToRem(24)
                                : isTablet
                                  ? pxToRem(35)
                                  : pxToRem(72)
                        }
                    >
                        <TableViewedCases handleFilterData={handleFilterData} />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};
export default ViewedCases;
