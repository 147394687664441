import * as React from 'react';
import { Key, useMemo } from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import borders from 'assets/theme/base/borders';
import dayjs from 'dayjs';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { SxProps } from '@mui/system';
import { RowDataType, TableMetaData } from 'types/common/flexible-table';
import pxToRem from 'assets/theme/function/px-to-rem';

interface Props {
    tableMetaDataList: TableMetaData[];
    tableDataList: RowDataType[];
}

const initTableHeadCell = (
    value: string | number | boolean,
    key: string,
    sx?: SxProps,
) => {
    return (
        <TableCell key={key} sx={sx}>
            {value}
        </TableCell>
    );
};

const tableBodyCellComponent = (
    cellValue: any,
    index: string,
    textMaxLength?: number,
): ReactJSXElement => {
    return textMaxLength ? (
        <Tooltip title={cellValue} followCursor key={index}>
            <TableCell
                sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                }}
            >
                {cutCellText(cellValue, textMaxLength)}
            </TableCell>
        </Tooltip>
    ) : (
        <TableCell key={index}>{cellValue}</TableCell>
    );
};

const cutCellText = (cellText: any, maxLength: any) => {
    if (cellText.length <= maxLength) {
        return cellText;
    }
    const endTextPart = cellText.slice(maxLength);
    const firstSpaceIndex = endTextPart.indexOf(' ');

    return firstSpaceIndex !== -1
        ? cellText.slice(0, maxLength + firstSpaceIndex) + ' ... '
        : cellText;
};

const initBodyValueCell = (
    cellComponentValues: ReactJSXElement[],
    index: any,
    RowComponent: React.ComponentType<{
        key: Key | null | undefined;
        data: RowDataType;
        children: React.ReactNode;
    }>,
    rowData: RowDataType,
) => {
    return (
        <RowComponent key={index} data={rowData}>
            {cellComponentValues.map(
                (cellComponentValue) => cellComponentValue,
            )}
        </RowComponent>
    );
};

const FlexibleTable: React.FC<Props> = (props: Props) => {
    const { tableMetaDataList, tableDataList } = props;
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const initializeTableData = useMemo(() => {
        const headContent = [];
        const bodyContent = [];

        let currI = 1;
        for (const data of tableMetaDataList) {
            const tableLength = tableMetaDataList.length;
            if (currI === tableLength) {
                data.sx = {
                    borderTopRightRadius: borders.borderRadius.md,
                    borderRight: 'none',
                };
            } else if (currI === 1) {
                data.sx = {
                    borderTopLeftRadius: borders.borderRadius.md,
                };
            } else data.sx = undefined;

            headContent.push(
                initTableHeadCell(
                    data.title ? data.title : '',
                    data.key,
                    data.sx,
                ),
            );

            currI++;
        }

        let rowValue;
        for (let i = 0; i < tableDataList.length; i++) {
            const bodyCellValues: ReactJSXElement[] = [];
            for (const tableMetaData of tableMetaDataList) {
                if (tableDataList[i].hasOwnProperty(tableMetaData.key)) {
                    if (
                        tableDataList[i][tableMetaData.key] &&
                        tableMetaData.type === 'date' &&
                        tableMetaData.format
                    ) {
                        rowValue = dayjs(
                            tableDataList[i][tableMetaData.key],
                        ).format(tableMetaData.format);
                    } else {
                        rowValue = tableDataList[i][tableMetaData.key];
                    }
                } else {
                    rowValue = '';
                }

                const bodyCellValue: ReactJSXElement = tableBodyCellComponent(
                    rowValue,
                    tableMetaData.key,
                    tableMetaData.textMaxLength,
                );

                bodyCellValues.push(bodyCellValue);
            }

            bodyContent.push(
                initBodyValueCell(
                    bodyCellValues,
                    i + 1,
                    tableDataList[i].rowComponent,
                    tableDataList[i],
                ),
            );
        }

        return (
            <>
                <TableHead>
                    <TableRow>{headContent.map((value) => value)}</TableRow>
                </TableHead>
                <TableBody>{bodyContent?.map((value) => value)}</TableBody>
            </>
        );
    }, [tableMetaDataList, tableDataList]);

    return (
        <Box mx={isMobile ? pxToRem(24) : isTablet ? pxToRem(35) : pxToRem(72)}>
            <TableContainer>
                <Table stickyHeader aria-label="case search data">
                    {initializeTableData}
                </Table>
            </TableContainer>
        </Box>
    );
};

export default FlexibleTable;
