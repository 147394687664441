import { TypographyOptions } from '@mui/material/styles/createTypography';
import pxToRem from 'assets/theme/function/px-to-rem';
import colors from 'assets/theme/base/colors';

const { text } = colors;

interface TypographyOptionsCustom extends TypographyOptions {
    size?: any;
}

const baseProperties = {
    fontFamily: 'GHEA-Grapalat',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    fontSizeXXS: pxToRem(10),
    fontSizeXS: pxToRem(12),
    fontSizeSM: pxToRem(14),
    fontSizeMD: pxToRem(16),
    fontSizeLG: pxToRem(18),
    fontSizeXL: pxToRem(20),
    fontSize2XL: pxToRem(24),
    fontSize3XL: pxToRem(32),
};

const baseHeadingProperties = {
    fontFamily: baseProperties.fontFamily,
    color: text.primary,
    fontWeight: baseProperties.fontWeightRegular,
};

const typography: TypographyOptionsCustom = {
    fontFamily: baseProperties.fontFamily,
    fontWeightLight: baseProperties.fontWeightLight,
    fontWeightRegular: baseProperties.fontWeightRegular,
    fontWeightMedium: baseProperties.fontWeightMedium,
    fontWeightBold: baseProperties.fontWeightBold,

    h1: {
        fontSize: baseProperties.fontSize3XL,
        lineHeight: pxToRem(42),
        ...baseHeadingProperties,
        fontWeight: baseProperties.fontWeightBold,
        '@media (max-width:900px)': {
            fontSize: baseProperties.fontSize2XL,
        },
    },

    h2: {
        fontSize: baseProperties.fontSize2XL,
        lineHeight: pxToRem(32),
        ...baseHeadingProperties,
        fontWeight: baseProperties.fontWeightBold,
    },

    h3: {
        fontSize: baseProperties.fontSizeXL,
        lineHeight: pxToRem(30),
        ...baseHeadingProperties,
        fontWeight: baseProperties.fontWeightBold,
    },

    h4: {
        fontSize: baseProperties.fontSizeLG,
        lineHeight: pxToRem(24),
        ...baseHeadingProperties,
    },

    h5: {
        fontSize: baseProperties.fontSizeMD,
        lineHeight: pxToRem(24),
        ...baseHeadingProperties,
        fontWeight: baseProperties.fontWeightBold,
    },

    h6: {
        fontSize: baseProperties.fontSizeSM,
        lineHeight: pxToRem(20),
        ...baseHeadingProperties,
    },

    subtitle1: {
        fontSize: baseProperties.fontSizeXS,
        lineHeight: pxToRem(18),
        ...baseHeadingProperties,
    },

    subtitle2: {
        fontSize: baseProperties.fontSizeXXS,
        lineHeight: pxToRem(16),
        ...baseHeadingProperties,
    },

    body1: {
        fontFamily: baseProperties.fontFamily,
        fontSize: baseProperties.fontSizeMD,
        fontWeight: baseProperties.fontWeightRegular,
        lineHeight: 1.625,
    },

    body2: {
        fontFamily: baseProperties.fontFamily,
        fontSize: baseProperties.fontSizeMD,
        fontWeight: baseProperties.fontWeightLight,
        lineHeight: 1.6,
    },

    button: {
        fontSize: baseProperties.fontSizeMD,
        lineHeight: pxToRem(24),
        textTransform: 'none',
        ...baseHeadingProperties,
    },

    caption: {
        fontFamily: baseProperties.fontFamily,
        fontSize: baseProperties.fontSizeXS,
        fontWeight: baseProperties.fontWeightLight,
        lineHeight: 1,
    },

    overline: {
        fontFamily: baseProperties.fontFamily,
    },

    size: {
        xxs: baseProperties.fontSizeXXS,
        xs: baseProperties.fontSizeXS,
        sm: baseProperties.fontSizeSM,
        md: baseProperties.fontSizeMD,
        lg: baseProperties.fontSizeLG,
        xl: baseProperties.fontSizeXL,
        xxl: baseProperties.fontSize2XL,
        xxxl: baseProperties.fontSize3XL,
    },
};

export default typography;
