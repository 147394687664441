import * as React from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material';
import pxToRem from 'assets/theme/function/px-to-rem';
import { ReactComponent as EmailIcon } from 'assets/images/dialog/emailIcon.svg';
import colors from 'assets/theme/base/colors';
interface Props {
    openConfirmEmailDialog: boolean;
    setOpenConfirmEmailDialog: React.Dispatch<React.SetStateAction<boolean>>;
}
const ConfirmEmail: React.FC<Props> = (props: Props) => {
    const { openConfirmEmailDialog, setOpenConfirmEmailDialog } = props;

    const email = '......@gmail.com';

    return (
        <>
            <Dialog
                open={openConfirmEmailDialog}
                PaperProps={{
                    sx: {
                        width: pxToRem(540),
                    },
                }}
            >
                <Box>
                    <DialogTitle textAlign={'center'}>
                        <EmailIcon />
                        <Typography
                            variant={'h3'}
                            mt={pxToRem(24)}
                            textAlign={'center'}
                            //onClick={handelRegister}
                        >
                            էլեկտրոնային Հաստատում
                        </Typography>
                    </DialogTitle>
                    <DialogContent sx={{ mt: pxToRem(24) }}>
                        <Typography textAlign={'center'}>
                            Մենք նամակ ենք ուղարկել{' '}
                            <Box
                                color={colors.secondary.main}
                                sx={{ cursor: 'pointer' }}
                                component={'span'}
                            >
                                {email}
                            </Box>
                            էլ.հասցեին՝ հաստատելու վավերականությունը: Նամակը
                            ստանալուց հետո հետևեք նշված հղմանը` գրանցումն
                            ավարտելու համար:
                        </Typography>
                    </DialogContent>
                    <DialogActions
                        sx={{
                            display: 'block',
                            textAlign: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                textAlign: 'center',
                                justifyContent: 'center',
                                display: 'flex',
                            }}
                        >
                            <Typography variant={'h5'} fontWeight={400}>
                                Եթե չեք ստացել էլ. նամակ
                            </Typography>
                            <Typography
                                sx={{ cursor: 'pointer' }}
                                lineHeight={1.7}
                                variant={'h6'}
                                fontWeight={700}
                                color={colors.primary.main}
                            >
                                Կրկին ուղարկել
                            </Typography>
                        </Box>
                        <Button
                            onClick={() => {
                                setOpenConfirmEmailDialog(false);
                            }}
                            sx={{ mt: pxToRem(24) }}
                            variant={'outlined'}
                        >
                            Փակել
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
};
export default ConfirmEmail;
