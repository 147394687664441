import colors from 'assets/theme/base/colors';
import pxToRem from 'assets/theme/function/px-to-rem';

const { grey, secondary } = colors;

const checkbox = {
    styleOverrides: {
        root: {
            color: 'transparent',

            '& .MuiSvgIcon-root': {
                color: grey['300'],
                backgroundColor: grey['300'],
                borderRadius: pxToRem(5),
                fontSize: pxToRem(18),
            },

            '&.Mui-checked': {
                '& .MuiSvgIcon-root': {
                    backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 22 22'%3e%3cpath fill='' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")`,
                    color: secondary.main,
                    backgroundColor: colors.text.secondary,
                },
            },

            '&:hover': {
                backgroundColor: 'transparent',
            },

            /*  '&.Mui-focusVisible': {
                border: `${borderWidth[2]} solid ${info.main}`,
            },*/
        },

        sizeSmall: {
            padding: pxToRem(5),

            '& .MuiSvgIcon-root': {
                fontSize: pxToRem(16),
            },
        },

        /*colorPrimary: {
            color: colors.grey['300'],
            '&.Mui-checked': {
                '& .MuiSvgIcon-root': {
                    backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 22 22'%3e%3cpath fill='' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")`,
                    color: colors.secondary.main,
                    backgroundColor: colors.secondary.main,
                },
            },
        },

        colorSecondary: {
            color: borderColor,

            '& .MuiSvgIcon-root': {
                color: info.main,
                '&.Mui-checked': {
                    backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 22 22'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")`,
                    borderColor: info.main,
                },
            },
        },*/
    },
};

export default checkbox;
