import borders from 'assets/theme/base/borders';
import colors from 'assets/theme/base/colors';

const { borderRadius } = borders;

const pickersPopper = {
    styleOverrides: {
        paper: {
            backgroundColor: colors.white.main,
            borderRadius: borderRadius.xxl,
        },
    },
};

export default pickersPopper;
