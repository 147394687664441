import * as React from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
} from '@mui/material';
import pxToRem from 'assets/theme/function/px-to-rem';
import { SendResetPassword } from 'types/user/user';
import { useEffect, useState } from 'react';
import { openSnackbar } from 'store/slices/snackbar/snackbar-slice';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks/redux';
import { useSendResetPasswordMutation } from 'store/slices/user/user-reset-password';
import colors from 'assets/theme/base/colors';
interface Props {
    openSecretingDialog: boolean;
    setOpenSecretingDialog: React.Dispatch<React.SetStateAction<boolean>>;
    setOpenConfirmEmailDialog: React.Dispatch<React.SetStateAction<boolean>>;
}
const ResetSecreting: React.FC<Props> = (props: Props) => {
    const {
        openSecretingDialog,
        setOpenSecretingDialog,
        setOpenConfirmEmailDialog,
    } = props;
    const [user, setUser] = useState<SendResetPassword>({
        email: '',
    });
    const [errorMessage, setErrorMessage] = useState<string>('');
    const dispatch = useAppDispatch();

    let isValid = true;
    let errorText = 'Invalid';
    const [sendResetPassword, { isSuccess, error, isError }] =
        useSendResetPasswordMutation();

    const validatePlantingMaterialInfo = (): boolean => {
        if (user.email == '' || user.email == 'Invalid Date') {
            isValid = false;
            errorText = 'Դուք ունեք դատարկ դաշտեր';
        }

        if (!isValid) {
            dispatch(
                openSnackbar({
                    message: errorText,
                    severity: 'error',
                    open: true,
                    position: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                }),
            );
        }

        return isValid;
    };
    const handleSecretingReset = async () => {
        if (validatePlantingMaterialInfo()) {
            await sendResetPassword(user);
        }
    };
    const navigate = useNavigate();

    useEffect(() => {
        if (isSuccess) {
            navigate('/');
            setOpenConfirmEmailDialog(true);
            setOpenSecretingDialog(false);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (error && 'data' in error && isError) {
            setErrorMessage(error?.data.message);
            setOpenSecretingDialog(true);
        }
    }, [isError]);

    return (
        <>
            <Dialog
                open={openSecretingDialog}
                onClose={() => {
                    setOpenSecretingDialog(false);
                }}
                PaperProps={{
                    sx: {
                        width: pxToRem(540),
                    },
                }}
            >
                {isError ? (
                    <Typography color={colors.error.main} textAlign={'center'}>
                        {errorMessage}
                    </Typography>
                ) : (
                    ''
                )}
                <Box>
                    <DialogTitle textAlign={'center'} variant={'h3'}>
                        Վերականգնել գաղտնաբառը
                    </DialogTitle>
                    <DialogContent>
                        <Box paddingTop={pxToRem(20)}>
                            <TextField
                                sx={{ mt: pxToRem(10) }}
                                fullWidth
                                label="Էլ. հասցե"
                                type="email"
                                name="email"
                                aria-describedby="emailTextHelper"
                                inputProps={{
                                    'aria-label': 'email',
                                }}
                                onChange={(e) => {
                                    const { name, value } = e.target;
                                    setUser((prevState) => ({
                                        ...prevState,
                                        [name]: value,
                                    }));
                                }}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ display: 'block' }}>
                        <Box display={'flex'} justifyContent={'end'}>
                            <Button
                                className="logout-confirm-button"
                                variant="contained"
                                color="primary"
                                onClick={handleSecretingReset}
                            >
                                Շարունակել
                            </Button>
                        </Box>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
};
export default ResetSecreting;
