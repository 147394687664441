import colors from 'assets/theme/base/colors';
import GHEAGrapalatWoff2 from 'assets/fonts/GHEAGrapalat.woff2';
import GHEAGrapalatWoff from 'assets/fonts/GHEAGrapalat.woff';

const { info, white } = colors;

const globals = {
    '@font-face': [
        {
            fontFamily: 'GHEA-Grapalat',
            src: `url(${GHEAGrapalatWoff2}) format('woff2'), url(${GHEAGrapalatWoff}) format('woff')`,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontDisplay: 'swap',
        },
    ],
    /*html: {
        //scrollBehavior: 'smooth',
    },*/
    body: {
        backgroundColor: white.main,
        '*::-webkit-scrollbar': {
            width: '0.5em',
            height: '0.5em',
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: colors.text.secondary,
            //outline: '1px solid slategrey',
            borderRadius: '10px',
        },
    },
    '*, *::before, *::after': {
        margin: 0,
        padding: 0,
    },
    'a, a:link, a:visited': {
        textDecoration: 'none',
    },
    /*'.MuiMenuButton-root': {
        borderColor: white.main,
        backgroundColor: white.main,
    },*/
    'a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited': {
        color: info.main,
        transition: 'color 150ms ease-in',
    },
    'a.link:hover, .link:hover, a.link:focus, .link:focus': {
        color: info.main,
    },
};

export default globals;
