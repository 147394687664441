import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import routes from './routes';
import RouteElement from './route-element';
import { RouteParams } from 'types/routes/route-params';

const RouterView: React.FC = () => {
    const getRoutes = (allRoutes: Array<RouteParams>) => {
        return allRoutes.map((routeParam: RouteParams, index: number) => {
            return (
                <Route
                    path={routeParam.path}
                    key={routeParam.name + index}
                    element={<RouteElement {...routeParam} />}
                />
            );
        });
    };

    return (
        <Routes>
            {getRoutes(routes)}
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    );
};
export default RouterView;
