import { RouteParams } from 'types/routes/route-params';
import CaseSearch from 'pages/case-search';
import ViewedCases from 'pages/viewed-cases';
import RechargeAccount from 'pages/recharge-account';
import TransactionHistory from 'pages/transaction-history';
import PersonalInformation from 'pages/personal-information';
import ChangePassword from 'pages/change-password';
import headerBackground from 'assets/images/header/backgroundImage.png';

const routes: Array<RouteParams> = [
    {
        path: '/',
        name: 'case-search',
        title: 'Դատական Գործերի Որոնում',
        element: CaseSearch,
    },
    {
        path: 'reset-password/:resetPasswordToken',
        name: 'case-search',
        title: 'CaseSearch',
        element: CaseSearch,
    },
    {
        path: 'email-confirmation/:emailConfirmation',
        name: 'case-search',
        title: 'CaseSearch',
        element: CaseSearch,
    },
    {
        path: '/viewedCases',
        name: 'viewedCases',
        title: 'Դիտված գործեր',
        logo: headerBackground,
        element: ViewedCases,
        rules: {
            private: true,
        },
    },
    {
        path: '/recharge-account',
        name: 'recharge-account',
        title: 'Լիցքավորել հաշիվը',
        element: RechargeAccount,
        logo: headerBackground,
        rules: {
            private: true,
        },
    },
    {
        path: '/transaction history',
        name: 'transaction history',
        title: 'Գործարքների պատմություն',
        element: TransactionHistory,
        logo: headerBackground,
        rules: {
            private: true,
        },
    },
    {
        path: '/personal-information',
        name: 'personal-information',
        title: 'Անձնական տվյալներ',
        element: PersonalInformation,
        logo: headerBackground,
        rules: {
            private: true,
        },
    },
    {
        path: '/change-password',
        name: 'change-password',
        title: 'Փոխել գաղտնաբառը',
        element: ChangePassword,
        logo: headerBackground,
        rules: {
            private: true,
        },
    },
];

export default routes;
