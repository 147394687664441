import colors from 'assets/theme/base/colors';

const tableContainer = {
    styleOverrides: {
        root: {
            backgroundColor: colors.white.main,
            '.MuiTableCell-body': {
                textAlign: 'center',
            },
        },
    },
};

export default tableContainer;
