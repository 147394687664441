import * as React from 'react';
import { Box, Grid } from '@mui/material';
import pxToRem from 'assets/theme/function/px-to-rem';
import CurrentUserMenu from 'layouts/protected/personal-nu';
import colors from 'assets/theme/base/colors';

const RechargeAccount: React.FC = () => {
    return (
        <>
            <Grid container spacing={0}>
                <Grid item xs={12} md={1.8} lg={1.8} bgcolor={colors.info.main}>
                    <Box bgcolor={'transparent'}>
                        <CurrentUserMenu />
                    </Box>
                </Grid>
                <Grid item xs={12} md={10.2} lg={10.2}>
                    <Box m={pxToRem(32)}>aa</Box>
                </Grid>
            </Grid>
        </>
    );
};
export default RechargeAccount;
