const serializeObjectToURI = (
    obj: { [key: string]: any },
    uri: string,
): string => {
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            let value = obj[key];
            if (typeof value === 'undefined' || value === '') {
                continue;
            }

            if (typeof value === 'object') {
                if (value.length === 0) continue;

                uri =
                    uri + value.map((val: any) => `${key}[]=${val}&`).join('');
            } else {
                if (typeof obj[key] === 'boolean') {
                    value = value ? 1 : 0;
                }
                uri = uri + key + '=' + encodeURIComponent(String(value)) + '&';
            }
        }
    }

    // Remove the trailing '&' if it exists
    if (uri.endsWith('&')) {
        uri = uri.slice(0, -1);
    }

    return uri;
};
export default serializeObjectToURI;
