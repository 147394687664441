import colors from 'assets/theme/base/colors';
import pxToRem from 'assets/theme/function/px-to-rem';
import borders from 'assets/theme/base/borders';
import typography from 'assets/theme/base/typography';

const { borderWidth, borderColor, borderRadius } = borders;

const tab = {
    styleOverrides: {
        root: {
            borderBottom: `${borderWidth[1]} solid ${borderColor}`,
            fontSize: pxToRem(14),
            padding: 'unset',

            '&.Mui-selected': {
                height: pxToRem(50),
                color: colors.text.secondary,
                borderRadius: `${borderRadius.lg} ${borderRadius.lg} 0 0`,
                borderLeft: `${borderWidth[1]} solid ${borderColor}`,
                borderRight: `${borderWidth[1]} solid ${borderColor}`,
                borderTop: `${borderWidth[1]} solid ${borderColor}`,
                borderBottom: `${borderWidth[1]} solid ${colors.white.main}`,
                fontWeight: typography.fontWeightBold,
            },
        },
    },
};

export default tab;
