import colors from 'assets/theme/base/colors';
import typography from 'assets/theme/base/typography';

const { text, error } = colors;
const { size } = typography;

const inputLabel = {
    styleOverrides: {
        root: {
            fontSize: size.sm,
            color: text.placeholder,
            top: -5,

            '&.Mui-focused': {
                color: text.placeholder,
            },
            '&.Mui-error': {
                color: error.secondary,
            },
            '&.MuiInputLabel-shrink': {
                top: 0,

                '~ .MuiInputBase-root .MuiOutlinedInput-notchedOutline legend':
                    {
                        fontSize: '10px',
                    },

                '.MuiFormLabel-asterisk': {
                    color: error.secondary,
                },
            },
        },

        sizeSmall: {
            fontSize: size.xs,
            lineHeight: 1.625,

            '&.MuiInputLabel-shrink': {
                lineHeight: 1.6,
                fontSize: size.sm,

                '~ .MuiInputBase-root .MuiOutlinedInput-notchedOutline legend':
                    {
                        //fontSize: '0.72em',
                    },
            },
        },
    },
};

export default inputLabel;
