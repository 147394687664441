import colors from 'assets/theme/base/colors';
import pxToRem from 'assets/theme/function/px-to-rem';

const { secondary } = colors;

const select = {
    styleOverrides: {
        select: {
            display: 'grid',
            alignItems: 'center',
            padding: `0 ${pxToRem(12)}`,
            border: '1px solid rgba(32, 36, 61, 0.1)',

            '& .Mui-selected': {
                backgroundColor: secondary.light,
            },
        },

        selectMenu: {
            background: 'none',
            height: 'none',
            minHeight: 'none',
            overflow: 'unset',
        },

        icon: {
            display: 'none',
        },
    },
};

export default select;
