import * as React from 'react';
import {
    Box,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import pxToRem from 'assets/theme/function/px-to-rem';
import CurrentUserMenu from 'layouts/protected/personal-nu';
import colors from 'assets/theme/base/colors';
import { ReactComponent as Eye } from 'assets/images/group.svg';
import { ReactComponent as NounEye } from 'assets/images/noun.svg';
import { ChangPasswordRequest } from 'types/chang-password/chang-password';
import { openSnackbar } from 'store/slices/snackbar/snackbar-slice';
import { useChangPasswordMutation } from 'store/slices/user/user-reset-password';
import { useAppDispatch } from 'hooks/redux';

const ChangePasswrd: React.FC = () => {
    const [personalInfoPassword, setPersonalInfoPassword] =
        useState<ChangPasswordRequest>();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [showPass, setShowPass] = useState<boolean>(false);
    const [showPassNew, setShowPassNew] = useState<boolean>(false);
    const [showPassNewSave, setShowPassNewSave] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const [changePassword, { error, isError }] = useChangPasswordMutation();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    const validateFilterData = (): boolean => {
        let isValid = true;
        let errorText = '';

        if (
            personalInfoPassword?.oldPassword == '' ||
            personalInfoPassword?.oldPassword == 'Invalid Date'
        ) {
            isValid = false;
            errorText = 'Дата периода не заполнена';
        }

        if (
            personalInfoPassword?.password == '' ||
            personalInfoPassword?.password == 'Invalid Date'
        ) {
            isValid = false;
            errorText = 'Дата периода не заполнена';
        }

        if (
            personalInfoPassword?.passwordConfirm == '' ||
            personalInfoPassword?.passwordConfirm
        ) {
            isValid = false;
            errorText = 'Дата периода не заполнена';
        }

        if (!isValid) {
            dispatch(
                openSnackbar({
                    message: errorText,
                    severity: 'error',
                    open: true,
                    position: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                }),
            );
        }

        return isValid;
    };

    const handleFilterDataPerson = () => {
        if (validateFilterData()) {
            if (personalInfoPassword) {
                changePassword(personalInfoPassword);
            }
        } else {
            errorMessage;
        }
    };

    useEffect(() => {
        if (error && 'data' in error) {
            setErrorMessage(error?.data.message);
        }
    }, [isError]);

    return (
        <>
            <Grid container spacing={0}>
                <Grid item xs={12} md={1.8} lg={1.8} bgcolor={colors.info.main}>
                    <Box bgcolor={'transparent'}>
                        <CurrentUserMenu />
                    </Box>
                </Grid>
                <Grid item xs={12} md={10.2} lg={10.2}>
                    <Box
                        ml={pxToRem(32)}
                        mr={
                            isMobile
                                ? pxToRem(24)
                                : isTablet
                                  ? pxToRem(35)
                                  : pxToRem(72)
                        }
                        mt={pxToRem(32)}
                    >
                        <Box display={'flex'} justifyContent={'center'}>
                            <Typography mb={pxToRem(16)} variant={'h3'}>
                                Խմբագրել գաղտնաբարը
                            </Typography>
                        </Box>
                        <Grid
                            container
                            rowSpacing={2}
                            columnSpacing={{ xs: 2, sm: 2, md: 3 }}
                            mb={pxToRem(9)}
                        >
                            <Grid item xs={12} md={6} lg={3} />
                            <Grid item xs={12} md={6} lg={6}>
                                <TextField
                                    sx={{ mt: pxToRem(10) }}
                                    fullWidth
                                    required
                                    label="Հին գաղտնաբառ"
                                    name="password"
                                    type={showPass ? 'text' : 'password'}
                                    aria-describedby="passwordTextHelper"
                                    InputProps={{
                                        'aria-label': 'password',
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() =>
                                                        setShowPass(
                                                            (current) =>
                                                                !current,
                                                        )
                                                    }
                                                    onMouseDown={(e) =>
                                                        e.preventDefault()
                                                    }
                                                    edge="end"
                                                >
                                                    {showPass ? (
                                                        <Eye />
                                                    ) : (
                                                        <NounEye />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={(e) => {
                                        const { name, value } = e.target;
                                        setPersonalInfoPassword(
                                            (
                                                prevState:
                                                    | ChangPasswordRequest
                                                    | undefined,
                                            ) => ({
                                                ...prevState,
                                                [name]: value,
                                                oldPassword: value,
                                            }),
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3} />
                        </Grid>
                        <Grid
                            container
                            rowSpacing={2}
                            columnSpacing={{ xs: 2, sm: 2, md: 3 }}
                            mb={pxToRem(9)}
                        >
                            <Grid item xs={12} md={6} lg={3} />
                            <Grid item xs={12} md={6} lg={6}>
                                <TextField
                                    sx={{ mt: pxToRem(10) }}
                                    fullWidth
                                    required
                                    label="Նոր գաղտնաբառ"
                                    name="password"
                                    type={showPassNew ? 'text' : 'password'}
                                    aria-describedby="passwordTextHelper"
                                    InputProps={{
                                        'aria-label': 'password',
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() =>
                                                        setShowPassNew(
                                                            (current) =>
                                                                !current,
                                                        )
                                                    }
                                                    onMouseDown={(e) =>
                                                        e.preventDefault()
                                                    }
                                                    edge="end"
                                                >
                                                    {showPassNew ? (
                                                        <Eye />
                                                    ) : (
                                                        <NounEye />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={(e) => {
                                        const { name, value } = e.target;
                                        setPersonalInfoPassword(
                                            (
                                                prevState:
                                                    | ChangPasswordRequest
                                                    | undefined,
                                            ) => ({
                                                ...prevState,
                                                [name]: value,
                                                password: value,
                                            }),
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3} />
                        </Grid>
                        <Grid
                            container
                            rowSpacing={2}
                            columnSpacing={{ xs: 2, sm: 2, md: 3 }}
                        >
                            <Grid item xs={12} md={6} lg={3} />
                            <Grid item xs={12} md={6} lg={6}>
                                <TextField
                                    sx={{ mt: pxToRem(10) }}
                                    fullWidth
                                    required
                                    label="Հաստատել նոր գաղտնաբառ"
                                    name="password"
                                    type={showPassNewSave ? 'text' : 'password'}
                                    aria-describedby="passwordTextHelper"
                                    InputProps={{
                                        'aria-label': 'password',
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() =>
                                                        setShowPassNewSave(
                                                            (current) =>
                                                                !current,
                                                        )
                                                    }
                                                    onMouseDown={(e) =>
                                                        e.preventDefault()
                                                    }
                                                    edge="end"
                                                >
                                                    {showPassNewSave ? (
                                                        <Eye />
                                                    ) : (
                                                        <NounEye />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={(e) => {
                                        const { name, value } = e.target;
                                        setPersonalInfoPassword(
                                            (
                                                prevState:
                                                    | ChangPasswordRequest
                                                    | undefined,
                                            ) => ({
                                                ...prevState,
                                                [name]: value,
                                                passwordConfirm: value,
                                            }),
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3} />
                        </Grid>
                        <Box
                            display={'flex'}
                            justifyContent={'end'}
                            mt={pxToRem(32)}
                        >
                            <Button
                                onClick={() => handleFilterDataPerson()}
                                variant={'contained'}
                            >
                                Պահպանել
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};
export default ChangePasswrd;
