import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SnackbarState } from 'types/snackbar/snackbar-state';
import { RootState } from 'store/index';

const initialState: SnackbarState = {
    open: false,
    message: '',
    severity: 'success',
    autoHideDuration: 5000,
    position: {
        vertical: 'bottom',
        horizontal: 'left',
    },
};

const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        openSnackbar: (state, action: PayloadAction<SnackbarState>) => {
            state.open = true;
            state.message = action.payload.message
                ? action.payload.message
                : state.message;
            state.severity = action.payload.severity
                ? action.payload.severity
                : state.severity;
            state.autoHideDuration = action.payload.autoHideDuration
                ? action.payload.autoHideDuration
                : state.autoHideDuration;
            state.position = action.payload.position
                ? action.payload.position
                : state.position;
        },
        closeSnackbar: (state) => {
            state.open = false;
        },
    },
});

export const { openSnackbar, closeSnackbar } = snackbarSlice.actions;

export const selectSnackbarOptions = (state: RootState) => state.snackbar;

export default snackbarSlice.reducer;
