import * as React from 'react';
import {
    Box,
    styled,
    Stack,
    TablePagination,
    TableRow,
    Backdrop,
    CircularProgress,
} from '@mui/material';
import pxToRem from 'assets/theme/function/px-to-rem';
import { useEffect, useState } from 'react';
import CaseTypeFilter from 'pages/case-search/case-type-filter';
import FlexibleTable from 'pages/case-search/case-search-result/flexible-table';
import { RowDataType, TableMetaData } from 'types/common/flexible-table';
import {
    bankruptcyCaseTableMetaData,
    civilCaseTableMetaData,
    criminalCaseTableMetaData,
    paymentOrderCaseTableMetaData,
} from 'pages/case-search/case-search-result/table-meta-data';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
    currentCaseResetState,
    currentCaseSearchState,
    endCaseSearch,
    resetCaseEnd,
    startCaseSearch,
} from 'store/slices/cases/case-search-slice';
import { useLazySearchCasesQuery } from 'store/slices/cases/cases';
import { CasesTypeRequest } from 'types/cases/cases';
import { openSnackbar } from 'store/slices/snackbar/snackbar-slice';
import CasesDialog from 'pages/case-search/cases-dialog';

const StyledTableRow = styled(TableRow, {
    shouldForwardProp: (prop) => prop !== 'data',
})<{ data: RowDataType }>(() => {
    const cssObject: any = {};

    return cssObject;
});

export const initialMainFilterData: CasesTypeRequest = {
    caseType: '',
    caseNumber: '',
    claim: '',
    claimantApplicantLastName: '',
    claimantApplicantFirstName: '',
    claimantApplicantOrganizationName: '',
    respondentFirstName: '',
    respondentLastName: '',
    respondentOrganizationName: '',
    applicationReceivedEnd: '',
    applicationReceivedStart: '',
    trialDateStart: '',
    trialDateEnd: '',
    courtActDateStart: '',
    courtActDateEnd: '',
    courtID: '',
    judgeID: '',
};

const CaseSearch: React.FC = () => {
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(50);
    const [pagesCount, setPagesCount] = useState<number>(0);

    const [caseType, setCaseType] = React.useState<string>('civil');
    const [tableMetaDataList, setTableMetaDataList] = useState<TableMetaData[]>(
        civilCaseTableMetaData,
    );
    const [tableDataList, setTableDataList] = useState<RowDataType[]>([]);
    const [searchingFields, setSearchingFields] = useState<CasesTypeRequest>();
    const [loadingOn, setLoadingOn] = useState(false);

    const { searchState } = useAppSelector(currentCaseSearchState);
    const { cancelState } = useAppSelector(currentCaseResetState);
    const dispatch = useAppDispatch();

    const [searchCaseTrigger, { data, error, isError, isFetching, requestId }] =
        useLazySearchCasesQuery();

    useEffect(() => {
        setTableDataList([]);
        setPage(1);
        setPagesCount(0);

        if (
            caseType === 'criminal' ||
            caseType === 'anticoruption_criminal_case'
        ) {
            setTableMetaDataList(criminalCaseTableMetaData);
        } else if (caseType === 'pay_order') {
            setTableMetaDataList(paymentOrderCaseTableMetaData);
        } else if (caseType === 'bankruptcy') {
            setTableMetaDataList(bankruptcyCaseTableMetaData);
        } else {
            setTableMetaDataList(civilCaseTableMetaData);
        }
    }, [caseType]);

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setPageSize(Number(event.target.value));
        handleChangePage(0);
    };

    const handleChangePage = (currentPage: number) => {
        setPage(currentPage + 1);
        dispatch(startCaseSearch('change-page'));
    };

    useEffect(() => {
        if (searchState) {
            setLoadingOn(true);

            let currPage = 1;
            if (searchState === 'change-page') {
                currPage = page;
            } else {
                setPage(1);
            }
            searchCaseTrigger({
                ...searchingFields,
                caseType,
                page: currPage,
                pageSize,
            });
            dispatch(endCaseSearch());
        }
    }, [searchState]);

    useEffect(() => {
        if (cancelState) {
            setSearchingFields(initialMainFilterData);
            dispatch(resetCaseEnd());
        }
    }, [cancelState]);

    useEffect(() => {
        setPagesCount(Number(data?.rowsCount));

        if (data?.cases) {
            const tableData: RowDataType[] = data.cases.map((casesInfo) => {
                return {
                    ...casesInfo,
                    case_show: <CasesDialog caseId={casesInfo.caseID} />,
                    rowComponent: StyledTableRow,
                };
            });
            setTableDataList(tableData);
        }
    }, [data, requestId]);

    useEffect(() => {
        setLoadingOn(isFetching);
    }, [isFetching]);

    useEffect(() => {
        if (data?.cases !== undefined) {
            window.scrollTo({ top: 1000, behavior: 'smooth' });
        } else {
            window.scrollTo({ top: -1000, behavior: 'smooth' });
        }
    }, [data]);

    useEffect(() => {
        if (error && 'data' in error) {
            dispatch(
                openSnackbar({
                    message: error?.data.message,
                    severity: 'error',
                    open: true,
                    position: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                }),
            );
            setLoadingOn(false);
        }
    }, [isError]);

    return (
        <>
            <Box
            /* mx={
                    isMobile
                        ? pxToRem(24)
                        : isTablet
                          ? pxToRem(35)
                          : pxToRem(72)
                }*/
            >
                <CaseTypeFilter
                    setSearchingFields={setSearchingFields}
                    searchingFields={searchingFields}
                    caseType={caseType}
                    setCaseType={setCaseType}
                />

                <Box mt={pxToRem(24)} mb={pxToRem(30)}>
                    <FlexibleTable
                        tableMetaDataList={tableMetaDataList}
                        tableDataList={tableDataList}
                    />

                    {pagesCount > 0 && (
                        <Stack spacing={2} alignItems={'end'}>
                            <TablePagination
                                rowsPerPageOptions={[50, 100, 200]}
                                component={'div'}
                                count={pagesCount}
                                rowsPerPage={pageSize}
                                labelRowsPerPage={'Տողերի քանակ'}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                page={page - 1}
                                onPageChange={(event, page) =>
                                    handleChangePage(page)
                                }
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Stack>
                    )}
                </Box>
                <Backdrop
                    transitionDuration={1}
                    sx={{
                        color: '#ffff',
                        // backgroundColor: colors.grey['100'],
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={loadingOn}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Box>
        </>
    );
};
export default CaseSearch;
