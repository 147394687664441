import pxToRem from 'assets/theme/function/px-to-rem';
import colors from 'assets/theme/base/colors';
import rgba from 'assets/theme/function/rgba';

const { white } = colors;

const appBar = {
    styleOverrides: {
        root: {
            backgroundColor: rgba(white.main, 0.16),
            boxShadow: 'none',
            WhiteSpace: 'nowrap',
            //padding: `${pxToRem(10)} ${pxToRem(24)}`,
            backdropFilter: `blur(${pxToRem(10)})`,
        },

        paper: {
            //width: pxToRem(250),
            //height: 'none',
            //margin: pxToRem(16),
            //borderRadius: borderRadius.xl,
            border: 'none',
        },

        paperAnchorDockedLeft: {
            borderRight: 'none',
        },
    },
};

export default appBar;
