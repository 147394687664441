import { apiSlice } from 'store/slices/api/api-slice';
import {
    SearchCourtResponse,
    SearchJudgeResponse,
} from 'types/search-courts-judge/search-courtes-judge';

const searchCourtsJudge = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        searchCourts: builder.query<SearchCourtResponse[], void>({
            query: () => ({
                url: '/public/get-courts?',
                method: 'GET',
            }),
        }),
        searchJudge: builder.query<SearchJudgeResponse[], void>({
            query: () => ({
                url: '/public/get-judges?',
                method: 'GET',
            }),
        }),
    }),
});
export const { useSearchCourtsQuery, useSearchJudgeQuery } = searchCourtsJudge;
