import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Paper, Stack, TablePagination } from '@mui/material';
import pxToRem from 'assets/theme/function/px-to-rem';
import { useEffect, useState } from 'react';
import colors from 'assets/theme/base/colors';
import { useLazyGetViewedCasesListQuery } from 'store/slices/cases/viewed-cases-list';

interface Props {
    handleFilterData: any;
}

const TableViewedCases: React.FC<Props> = (props: Props) => {
    const { handleFilterData } = props;
    const [pagesCount, setPagesCount] = useState<number>(0);
    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(100);

    const [viewedCasesListQuery, { data }] = useLazyGetViewedCasesListQuery();

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setPageSize(Number(event.target.value));
        setPage(1);
    };

    useEffect(() => {
        viewedCasesListQuery({
            page: page,
            pageSize: pageSize,
        });

        setPagesCount(0);
    }, [page, pageSize]);

    let N = 1;

    return (
        <>
            <Paper sx={{ width: '100%', overflow: 'unset', boxShadow: 'none' }}>
                <TableContainer sx={{ maxHeight: '60vh' }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead
                            sx={{
                                backgroundColor: colors.info.main,
                            }}
                        >
                            <TableRow>
                                <TableCell align={'center'}>Հ/Հ</TableCell>
                                <TableCell align="center">
                                    Գործի համար
                                </TableCell>
                                <TableCell align="center">
                                    Տեղեկություն
                                </TableCell>
                                <TableCell align="center">Դատավոր</TableCell>
                                <TableCell align="center">
                                    Դիտման ամսաթիվ
                                </TableCell>
                                <TableCell
                                    sx={{ borderRight: 'none' }}
                                    align="center"
                                >
                                    Անձի մասին
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.cases.map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 1,
                                            borderColor: '#f2f2f2',
                                        },
                                        whiteSpace: 'wrap',
                                        overflow: 'hidden',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        {N++}
                                    </TableCell>
                                    <TableCell align="right">
                                        {row.caseNumber}
                                    </TableCell>
                                    <TableCell align="right">
                                        {row.claim}
                                    </TableCell>
                                    <TableCell align="right">
                                        {row.judge}
                                    </TableCell>
                                    <TableCell align="right">
                                        {row.viewDate}
                                    </TableCell>
                                    <TableCell
                                        sx={{ width: pxToRem(300) }}
                                        key={index}
                                        align="left"
                                    >
                                        {row.description.claimantApplicant !==
                                            '' && (
                                            <Box
                                                textAlign={'left'}
                                                color={colors.error.main}
                                            >
                                                Հայցոր:
                                                <Box
                                                    color={colors.text.primary}
                                                >
                                                    {
                                                        row.description
                                                            .claimantApplicant
                                                    }
                                                </Box>
                                            </Box>
                                        )}
                                        {row.description.respondent !== '' && (
                                            <Box
                                                textAlign={'left'}
                                                color={colors.error.main}
                                            >
                                                Պատասղանող:
                                                <Box
                                                    color={colors.text.primary}
                                                >
                                                    {row.description.respondent}
                                                </Box>
                                            </Box>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {pagesCount >= 0 && (
                    <Stack spacing={2} py={pxToRem(20)} alignItems={'end'}>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            component={'div'}
                            count={pagesCount}
                            rowsPerPage={pageSize}
                            page={page - 1}
                            labelRowsPerPage={'Տողերի քանակ'}
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            onPageChange={(event, page) =>
                                handleFilterData(page)
                            }
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Stack>
                )}
            </Paper>
        </>
    );
};
export default TableViewedCases;
