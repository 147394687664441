import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/index';
import { CaseSearchState } from 'types/cases/case-search-state';

const initialState: CaseSearchState = {
    searchState: null,
    cancelState: null,
};

export const caseSearchSlice = createSlice({
    name: 'caseSearch',
    initialState,
    reducers: {
        startCaseSearch: (
            state: CaseSearchState,
            action: PayloadAction<CaseSearchState['searchState']>,
        ) => {
            state.searchState = action.payload;
        },
        endCaseSearch: (state: CaseSearchState) => {
            state.searchState = null;
        },
        resetCaseStart: (
            state: CaseSearchState,
            action: PayloadAction<CaseSearchState['cancelState']>,
        ) => {
            state.cancelState = action.payload;
        },
        resetCaseEnd: (state: CaseSearchState) => {
            state.cancelState = null;
        },
    },
});

export const { startCaseSearch, endCaseSearch, resetCaseStart, resetCaseEnd } =
    caseSearchSlice.actions;

export default caseSearchSlice.reducer;

export const currentCaseSearchState = (state: RootState) => state.caseSearch;
export const currentCaseResetState = (state: RootState) => state.cancelCase;
