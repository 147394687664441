import colors from 'assets/theme/base/colors';
import typography from 'assets/theme/base/typography';

const { text } = colors;
const { size } = typography;

const input = {
    styleOverrides: {
        root: {
            fontSize: size.xs,
            color: text.primary,

            /* '&:hover:not(.Mui-disabled):before': {
                borderBottom: `${borderWidth[1]} solid ${inputBorderColor}`,
            },

            '&:before': {
                borderColor: inputBorderColor,
            },

            '&:after': {
                borderColor: info.main,
            },*/
        },
    },
};

export default input;
