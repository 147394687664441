import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from 'store/slices/api/api-slice';
import userReducer from 'store/slices/user/user-slice';
import snackbarSlice from 'store/slices/snackbar/snackbar-slice';
import caseSearchSlice from 'store/slices/cases/case-search-slice';

const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        user: userReducer,
        snackbar: snackbarSlice,
        caseSearch: caseSearchSlice,
        cancelCase: caseSearchSlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat(
            apiSlice.middleware,
        ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
