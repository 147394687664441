import { apiSlice } from '../api/api-slice';
import {
    AccessTokenPayload,
    UserRegisterRequest,
    UserRequest,
    UserResponse,
    UserSignOut,
} from 'types/user/user';
import { MutationLifecycleApi } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { BaseQueryFn } from '@reduxjs/toolkit/src/query/baseQueryTypes';
import { logOutUser, setUser } from './user-slice';
import { jwtDecode } from 'jwt-decode';
import { UserState } from 'types/user/user-state';

export const userAuthApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        signIn: builder.mutation<UserResponse, UserRequest>({
            query: (userRequest: UserRequest) => ({
                url: '/auth/login',
                method: 'POST',
                body: { ...userRequest },
            }),
            async onQueryStarted(
                arg: UserRequest,
                api: MutationLifecycleApi<
                    UserRequest,
                    BaseQueryFn,
                    UserResponse
                >,
            ): Promise<void> {
                try {
                    const { data } = await api.queryFulfilled;

                    const accessTokenData: AccessTokenPayload = jwtDecode(
                        data.token,
                    );
                    const state: UserState = {
                        user: {
                            email: accessTokenData.username,
                            roles: accessTokenData.roles,
                        },
                    };

                    localStorage.setItem(
                        'user',
                        JSON.stringify(accessTokenData),
                    );

                    localStorage.setItem('access_token', data.token);
                    localStorage.setItem('refresh_token', data.refresh_token);

                    api.dispatch(setUser(state.user));
                } catch (error) {
                    console.log(error);
                }
            },
        }),
        signOut: builder.mutation<void, UserSignOut>({
            query: (userSignOut: UserSignOut) => ({
                url: '/logout',
                method: 'POST',
                body: { ...userSignOut },
            }),
            async onQueryStarted(
                arg: UserSignOut,
                api: MutationLifecycleApi<UserSignOut, BaseQueryFn, void>,
            ): Promise<void> {
                try {
                    await api.queryFulfilled;

                    localStorage.removeItem('user');
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('refresh_token');

                    api.dispatch(logOutUser());
                } catch (error) {}
            },
        }),
        register: builder.mutation({
            query: (userRegisterRequest: UserRegisterRequest) => ({
                url: '/auth/register',
                method: 'POST',
                body: { ...userRegisterRequest },
            }),
            async onQueryStarted(
                arg: UserRegisterRequest,
                api: MutationLifecycleApi<
                    UserRegisterRequest,
                    BaseQueryFn,
                    UserResponse
                >,
            ): Promise<void> {
                try {
                    const { data } = await api.queryFulfilled;

                    const accessTokenData: AccessTokenPayload = jwtDecode(
                        data.token,
                    );
                    const state: UserState = {
                        user: {
                            email: accessTokenData.username,
                            roles: accessTokenData.roles,
                        },
                    };

                    localStorage.setItem(
                        'user',
                        JSON.stringify(accessTokenData),
                    );

                    localStorage.setItem('access_token', data.token);
                    localStorage.setItem('refresh_token', data.refresh_token);

                    api.dispatch(setUser(state.user));
                } catch (error) {
                    console.log(error);
                }
            },
        }),
        /*
        register: builder.mutation({
            query: (credentials) => ({
                url: '/auth/user-details',
                method: 'POST',
                body: { ...credentials },
            }),
        }),
        forgotPassword: builder.mutation({
            query: (email) => ({
                url: '/auth/forgot-password',
                method: 'POST',
                body: { email },
            }),
        }),
        emailConfirm: builder.mutation({
            query: (credentials) => ({
                url: '/auth/email/verify',
                method: 'PUT',
                body: { ...credentials },
            }),
        }),
        resetPassword: builder.mutation({
            query: (credentials) => ({
                url: '/auth/reset-password',
                method: 'PATCH',
                body: { ...credentials },
            }),
        }),*/
    }),
});
export const { useSignInMutation, useRegisterMutation, useSignOutMutation } =
    userAuthApi;
