import colors from 'assets/theme/base/colors';
import typography from 'assets/theme/base/typography';
import pxToRem from 'assets/theme/function/px-to-rem';

const { white, primary, error } = colors;
const { size } = typography;

const contained = {
    base: {
        '&:hover': {
            opacity: 0.9,
        },
    },

    small: {
        height: pxToRem(24),
        padding: `${pxToRem(6)} ${pxToRem(15)}`,
    },
    medium: {
        height: pxToRem(44),
        '@media (max-width:740px)': {
            height: pxToRem(38),
        },
        //padding: `${pxToRem(12)} ${pxToRem(24)}`,
    },
    large: {
        height: pxToRem(50),
        padding: `${pxToRem(14)} ${pxToRem(30)}`,
        fontSize: size.md,
        // borderRadius: borderRadius.md,

        '& .material-icon, .material-icons-round, svg': {
            fontSize: size.md,
        },
    },

    primary: {
        backgroundColor: primary.main,
        color: white.main,

        '&:hover': {
            backgroundColor: primary.main,
        },

        '&:focus:not(:hover)': {
            //  backgroundColor: primary.main,
        },
    },
    secondary: {
        backgroundColor: primary.main,
        boxShadow: 'none',

        '&:hover': {
            backgroundColor: primary.main,
        },

        '&:focus:not(:hover)': {
            backgroundColor: primary.main,
        },
    },

    error: {
        backgroundColor: error.light,
        color: error.secondary,
        boxShadow: 'none',

        '&:hover': {
            backgroundColor: error.light,
            color: error.secondary,
        },
    },
};

export default contained;
