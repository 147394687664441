const iconButton = {
    styleOverrides: {
        root: {
            '&:hover': {
                backgroundColor: 'none',
            },
        },
    },
};

export default iconButton;
