import boxShadows from 'assets/theme/base/box-shadows';
import typography from 'assets/theme/base/typography';
import colors from 'assets/theme/base/colors';
import borders from 'assets/theme/base/borders';
import pxToRem from 'assets/theme/function/px-to-rem';

const { lg } = boxShadows;
const { size } = typography;
const { text, grey } = colors;
const { borderRadius } = borders;

const menu = {
    defaultProps: {
        disableAutoFocusItem: true,
    },

    styleOverrides: {
        paper: {
            minWidth: pxToRem(160),
            boxShadow: lg,
            padding: `${pxToRem(16)} ${pxToRem(8)}`,
            fontSize: size.sm,
            color: text.primary,
            //textAlign: 'left',
            backgroundColor: grey['100'],
            borderRadius: borderRadius.md,
        },
    },
};

export default menu;
