import borders from 'assets/theme/base/borders';
import typography from 'assets/theme/base/typography';
import colors from 'assets/theme/base/colors';
import pxToRem from 'assets/theme/function/px-to-rem';

const { fontWeightRegular, size } = typography;
const { borderRadius } = borders;

const calendarHeader = {
    styleOverrides: {
        root: {
            '& .MuiPickersArrowSwitcher-root': {
                '& .MuiButtonBase-root': {
                    borderRadius: borderRadius.round,
                    background: colors.white.main,
                    width: pxToRem(32),
                    height: pxToRem(32),
                    color: colors.text.primary,

                    '&.Mui-disabled': {
                        opacity: 0.45,
                        /*background: grey['300'],
                        color: text.placeholder,*/
                    },
                },
                '& .MuiPickersArrowSwitcher-spacer': {
                    width: pxToRem(30),
                },
            },
        },
        labelContainer: {
            color: colors.text.primary,
            fontSize: size.sm,
            fontWeight: fontWeightRegular,
        },
    },
};
export default calendarHeader;
