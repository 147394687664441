import pxToRem from 'assets/theme/function/px-to-rem';
import rgba from 'assets/theme/function/rgba';

function boxShadow(
    offset: any = [],
    radius: any = [],
    color: any,
    opacity: any,
    inset = '',
) {
    const [x, y] = offset;
    const [blur, spread] = radius;

    return `${inset} ${pxToRem(x)} ${pxToRem(y)} ${pxToRem(blur)} ${pxToRem(
        spread,
    )} ${rgba(color, opacity)}`;
}

export default boxShadow;
