import React from 'react';
import { AlertProps, Snackbar, Stack } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import {
    closeSnackbar,
    selectSnackbarOptions,
} from 'store/slices/snackbar/snackbar-slice';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
    function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    },
);

const SnackbarAlert = () => {
    const { open, message, severity, autoHideDuration, position } =
        useAppSelector(selectSnackbarOptions);
    const dispatch = useAppDispatch();

    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string,
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(closeSnackbar());
    };

    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar
                open={open}
                autoHideDuration={autoHideDuration}
                anchorOrigin={position}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity={severity}
                    sx={{ width: '100%' }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </Stack>
    );
};

export default SnackbarAlert;
