import colors from 'assets/theme/base/colors';

const tablePagination = {
    styleOverrides: {
        default: {
            fontWeight: 500,
            color: colors.text.primary,
        },
        selectLabel: {
            fontWeight: 500,
            color: colors.text.primary,
        },
        displayedRows: {
            fontWeight: 500,
            color: colors.text.primary,
        },
        select: {
            fontWeight: 500,
            color: colors.text.primary,
        },
        action: {
            fontWeight: 500,
            color: colors.text.primary,
        },
        root: {},
    },
};
export default tablePagination;
