import * as React from 'react';
import pxToRem from 'assets/theme/function/px-to-rem';
import {
    Autocomplete,
    Box,
    Collapse,
    Divider,
    Grid,
    Link,
    TextField,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { ReactComponent as CalendarMonthIcon } from 'assets/images/inputIcon/calendarIcon.svg';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/hy-am';
import dayjs, { Dayjs } from 'dayjs';
import { ReactComponent as ArrowRight } from 'assets/images/inputIcon/arrowRight.svg';
import colors from 'assets/theme/base/colors';
import { CasesTypeRequest } from 'types/cases/cases';
import {
    useSearchCourtsQuery,
    useSearchJudgeQuery,
} from 'store/slices/ search-judge-court/search-courts-judge';
import {
    SearchCourtResponse,
    SearchJudgeResponse,
} from 'types/search-courts-judge/search-courtes-judge';
import { FieldChangeHandlerContext } from '@mui/x-date-pickers/internals';
import { useRef, useState } from 'react';

interface Props {
    setSearchingFields?: React.Dispatch<
        React.SetStateAction<CasesTypeRequest | undefined>
    >;
    searchingFields?: CasesTypeRequest;
}

const AdvancedSearch: React.FC<Props> = (props: Props) => {
    const { setSearchingFields, searchingFields } = props;
    const [showAdvancedSearch, setShowAdvancedSearch] = React.useState(false);
    const [startDateError, setStartDateError] = useState<boolean>(false);
    const [endDateError, setEndDateError] = useState<boolean>(false);
    const startDateInputRef = useRef<HTMLInputElement>(null);
    const endDateInputRef = useRef<HTMLInputElement>(null);
    const searchCourts = useSearchCourtsQuery();
    const searchJudges = useSearchJudgeQuery();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    const openCloseAdvancedSearch = () =>
        setShowAdvancedSearch((state) => !state);

    return (
        <Box>
            <Divider sx={{ marginTop: pxToRem(15), marginBottom: pxToRem(10) }}>
                <Link
                    variant={'h5'}
                    fontWeight={'regular'}
                    color={colors.secondary.main}
                    underline={'none'}
                    onClick={() => openCloseAdvancedSearch()}
                >
                    {showAdvancedSearch
                        ? 'Կրճատել Որոնումը'
                        : 'Ընդլայնված որոնում'}
                </Link>
            </Divider>

            <Collapse in={showAdvancedSearch}>
                <Grid container rowSpacing={2} columnSpacing={2}>
                    <Grid item xs={12} sm={6} md={12}>
                        <Grid
                            container
                            spacing={2}
                            direction={isTablet ? 'column' : 'row'}
                        >
                            <Grid item xs={12} sm={12} md={4}>
                                <Autocomplete
                                    fullWidth
                                    disablePortal
                                    id="combo-box-demo"
                                    options={
                                        searchCourts.data
                                            ? searchCourts.data
                                            : []
                                    }
                                    getOptionKey={(
                                        option: SearchCourtResponse,
                                    ) => option.courtID}
                                    getOptionLabel={(
                                        option: SearchCourtResponse,
                                    ) => `${option.courtName}`}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Դատարան"
                                        />
                                    )}
                                    onChange={(e, value) => {
                                        if (setSearchingFields) {
                                            setSearchingFields(
                                                (prevState: any) => ({
                                                    ...prevState,
                                                    courtID: value?.courtID,
                                                }),
                                            );
                                        }
                                    }}
                                    sx={{
                                        mb: isMobile ? pxToRem(6) : pxToRem(0),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={8}>
                                <Box display={isTablet ? 'grid' : 'flex'}>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale={'hy-am'}
                                    >
                                        <DatePicker
                                            sx={{
                                                width: '100%',
                                                mb: isTablet
                                                    ? pxToRem(16)
                                                    : pxToRem(0),
                                            }}
                                            value={
                                                searchingFields?.applicationReceivedStart
                                                    ? dayjs(
                                                          searchingFields.applicationReceivedStart,
                                                      )
                                                    : null
                                            }
                                            disableFuture
                                            showDaysOutsideCurrentMonth
                                            views={['year', 'month', 'day']}
                                            format="DD MMMM YYYY"
                                            label={'Ստացման ամսաթիվ'}
                                            inputRef={startDateInputRef}
                                            onChange={(
                                                newValue: any,
                                                context: FieldChangeHandlerContext<any>,
                                            ) => {
                                                newValue &&
                                                context.validationError !==
                                                    'invalidDate'
                                                    ? setStartDateError(false)
                                                    : setStartDateError(true);
                                                if (setSearchingFields) {
                                                    setSearchingFields(
                                                        (prevState: any) => {
                                                            return {
                                                                ...prevState,
                                                                applicationReceivedStart:
                                                                    dayjs(
                                                                        newValue,
                                                                    ).format(
                                                                        'YYYY-MM-DD',
                                                                    ),
                                                            };
                                                        },
                                                    );
                                                }
                                            }}
                                            slots={{
                                                openPickerIcon:
                                                    CalendarMonthIcon,
                                            }}
                                            slotProps={{
                                                textField: {
                                                    InputLabelProps: {
                                                        margin: 'dense',
                                                        error: startDateError,
                                                    },
                                                },
                                                actionBar: ({
                                                    wrapperVariant,
                                                }) => ({
                                                    actions:
                                                        wrapperVariant ===
                                                        'mobile'
                                                            ? [
                                                                  'cancel',
                                                                  'accept',
                                                              ]
                                                            : [],
                                                }),
                                            }}
                                            dayOfWeekFormatter={(
                                                _day: string,
                                                date: Dayjs,
                                            ) =>
                                                dayjs(date)
                                                    .format('dd')
                                                    .toUpperCase()
                                            }
                                        />
                                    </LocalizationProvider>
                                    {isTablet ? (
                                        <Box mr={pxToRem(8)} />
                                    ) : (
                                        <Box
                                            px={pxToRem(1.5)}
                                            display={'flex'}
                                            alignItems={'center'}
                                        >
                                            <ArrowRight />
                                        </Box>
                                    )}
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale={'hy-am'}
                                    >
                                        <DatePicker
                                            sx={{ width: '100%' }}
                                            disableFuture
                                            showDaysOutsideCurrentMonth
                                            views={['year', 'month', 'day']}
                                            format="DD MMMM YYYY"
                                            label={'Ստացման ամսաթիվ'}
                                            value={
                                                searchingFields?.applicationReceivedEnd
                                                    ? dayjs(
                                                          searchingFields.applicationReceivedEnd,
                                                      )
                                                    : null
                                            }
                                            inputRef={endDateInputRef}
                                            onChange={(
                                                newValue: any,
                                                context: FieldChangeHandlerContext<any>,
                                            ) => {
                                                newValue &&
                                                context.validationError !==
                                                    'invalidDate'
                                                    ? setEndDateError(false)
                                                    : setEndDateError(true);
                                                if (setSearchingFields) {
                                                    setSearchingFields(
                                                        (prevState: any) => {
                                                            return {
                                                                ...prevState,
                                                                applicationReceivedEnd:
                                                                    dayjs(
                                                                        newValue,
                                                                    ).format(
                                                                        'YYYY-MM-DD',
                                                                    ),
                                                            };
                                                        },
                                                    );
                                                }
                                            }}
                                            slots={{
                                                openPickerIcon:
                                                    CalendarMonthIcon,
                                            }}
                                            slotProps={{
                                                textField: {
                                                    InputLabelProps: {
                                                        margin: 'dense',
                                                        error: endDateError,
                                                    },
                                                },
                                                actionBar: ({
                                                    wrapperVariant,
                                                }) => ({
                                                    actions:
                                                        wrapperVariant ===
                                                        'mobile'
                                                            ? [
                                                                  'cancel',
                                                                  'accept',
                                                              ]
                                                            : [],
                                                }),
                                            }}
                                            dayOfWeekFormatter={(
                                                _day: string,
                                                date: Dayjs,
                                            ) =>
                                                dayjs(date)
                                                    .format('dd')
                                                    .toUpperCase()
                                            }
                                        />
                                    </LocalizationProvider>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={4}>
                                <Autocomplete
                                    fullWidth
                                    disablePortal
                                    id="combo-box-demo"
                                    options={
                                        searchJudges.data
                                            ? searchJudges.data
                                            : []
                                    }
                                    getOptionKey={(
                                        option: SearchJudgeResponse,
                                    ) => option.judgeID}
                                    getOptionLabel={(
                                        option: SearchJudgeResponse,
                                    ) => `${option.judgeName}`}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Դատավոր"
                                        />
                                    )}
                                    onChange={(e, value) => {
                                        if (setSearchingFields) {
                                            setSearchingFields(
                                                (prevState: any) => ({
                                                    ...prevState,
                                                    judgeID: value?.judgeID,
                                                }),
                                            );
                                        }
                                    }}
                                    sx={{
                                        mb: isMobile ? pxToRem(6) : pxToRem(0),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={8}>
                                <Box
                                    alignItems={'center'}
                                    display={isTablet ? 'grid' : 'flex'}
                                >
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale={'hy-am'}
                                    >
                                        <DatePicker
                                            sx={{
                                                width: '100%',
                                                mb: isTablet
                                                    ? pxToRem(16)
                                                    : pxToRem(0),
                                            }}
                                            disableFuture
                                            showDaysOutsideCurrentMonth
                                            views={['year', 'month', 'day']}
                                            format="DD MMMM YYYY"
                                            label={'Դատաքննության ամսաթիվ'}
                                            value={
                                                searchingFields?.trialDateStart
                                                    ? dayjs(
                                                          searchingFields.trialDateStart,
                                                      )
                                                    : null
                                            }
                                            inputRef={startDateInputRef}
                                            onChange={(
                                                newValue: any,
                                                context: FieldChangeHandlerContext<any>,
                                            ) => {
                                                newValue &&
                                                context.validationError !==
                                                    'invalidDate'
                                                    ? setStartDateError(false)
                                                    : setStartDateError(true);
                                                if (setSearchingFields) {
                                                    setSearchingFields(
                                                        (prevState: any) => {
                                                            return {
                                                                ...prevState,
                                                                trialDateStart:
                                                                    dayjs(
                                                                        newValue,
                                                                    ).format(
                                                                        'YYYY-MM-DD',
                                                                    ),
                                                            };
                                                        },
                                                    );
                                                }
                                            }}
                                            slots={{
                                                openPickerIcon:
                                                    CalendarMonthIcon,
                                            }}
                                            slotProps={{
                                                textField: {
                                                    InputLabelProps: {
                                                        margin: 'dense',
                                                        error: startDateError,
                                                    },
                                                },
                                                actionBar: ({
                                                    wrapperVariant,
                                                }) => ({
                                                    actions:
                                                        wrapperVariant ===
                                                        'mobile'
                                                            ? [
                                                                  'cancel',
                                                                  'accept',
                                                              ]
                                                            : [],
                                                }),
                                            }}
                                            dayOfWeekFormatter={(
                                                _day: string,
                                                date: Dayjs,
                                            ) =>
                                                dayjs(date)
                                                    .format('dd')
                                                    .toUpperCase()
                                            }
                                        />
                                    </LocalizationProvider>
                                    {isTablet ? (
                                        <Box mr={pxToRem(8)} />
                                    ) : (
                                        <Box
                                            px={pxToRem(1.5)}
                                            display={'flex'}
                                            alignItems={'center'}
                                        >
                                            <ArrowRight />
                                        </Box>
                                    )}
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale={'hy-am'}
                                    >
                                        <DatePicker
                                            sx={{ width: '100%' }}
                                            disableFuture
                                            showDaysOutsideCurrentMonth
                                            views={['year', 'month', 'day']}
                                            format="DD MMMM YYYY"
                                            label={'Դատաքննության ամսաթիվ'}
                                            value={
                                                searchingFields?.trialDateEnd
                                                    ? dayjs(
                                                          searchingFields.trialDateEnd,
                                                      )
                                                    : null
                                            }
                                            inputRef={startDateInputRef}
                                            onChange={(
                                                newValue: any,
                                                context: FieldChangeHandlerContext<any>,
                                            ) => {
                                                newValue &&
                                                context.validationError !==
                                                    'invalidDate'
                                                    ? setEndDateError(false)
                                                    : setEndDateError(true);
                                                if (setSearchingFields) {
                                                    setSearchingFields(
                                                        (prevState: any) => {
                                                            return {
                                                                ...prevState,
                                                                trialDateEnd:
                                                                    dayjs(
                                                                        newValue,
                                                                    ).format(
                                                                        'YYYY-MM-DD',
                                                                    ),
                                                            };
                                                        },
                                                    );
                                                }
                                            }}
                                            slots={{
                                                openPickerIcon:
                                                    CalendarMonthIcon,
                                            }}
                                            slotProps={{
                                                textField: {
                                                    InputLabelProps: {
                                                        margin: 'dense',
                                                        error: endDateError,
                                                    },
                                                },
                                                actionBar: ({
                                                    wrapperVariant,
                                                }) => ({
                                                    actions:
                                                        wrapperVariant ===
                                                        'mobile'
                                                            ? [
                                                                  'cancel',
                                                                  'accept',
                                                              ]
                                                            : [],
                                                }),
                                            }}
                                            dayOfWeekFormatter={(
                                                _day: string,
                                                date: Dayjs,
                                            ) =>
                                                dayjs(date)
                                                    .format('dd')
                                                    .toUpperCase()
                                            }
                                        />
                                    </LocalizationProvider>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={8}
                        mb={pxToRem(8)}
                        mt={isMobile ? pxToRem(16) : pxToRem(0)}
                        display={isTablet ? 'grid' : isMobile ? 'grid' : 'flex'}
                    >
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={'hy-am'}
                        >
                            <DatePicker
                                sx={{
                                    width: '100%',
                                    mb: isTablet ? pxToRem(16) : pxToRem(0),
                                }}
                                disableFuture
                                showDaysOutsideCurrentMonth
                                views={['year', 'month', 'day']}
                                format="DD MMMM YYYY"
                                label={'Դատական ակտի ամսաթիվ'}
                                value={
                                    searchingFields?.courtActDateStart
                                        ? dayjs(
                                              searchingFields.courtActDateStart,
                                          )
                                        : null
                                }
                                inputRef={startDateInputRef}
                                onChange={(
                                    newValue: any,
                                    context: FieldChangeHandlerContext<any>,
                                ) => {
                                    newValue &&
                                    context.validationError !== 'invalidDate'
                                        ? setStartDateError(false)
                                        : setStartDateError(true);
                                    if (setSearchingFields) {
                                        setSearchingFields((prevState: any) => {
                                            return {
                                                ...prevState,
                                                courtActDateStart:
                                                    dayjs(newValue).format(
                                                        'YYYY-MM-DD',
                                                    ),
                                            };
                                        });
                                    }
                                }}
                                slots={{
                                    openPickerIcon: CalendarMonthIcon,
                                }}
                                slotProps={{
                                    textField: {
                                        InputLabelProps: {
                                            margin: 'dense',
                                            error: startDateError,
                                        },
                                    },
                                    actionBar: ({ wrapperVariant }) => ({
                                        actions:
                                            wrapperVariant === 'mobile'
                                                ? ['cancel', 'accept']
                                                : [],
                                    }),
                                }}
                                dayOfWeekFormatter={(
                                    _day: string,
                                    date: Dayjs,
                                ) => dayjs(date).format('dd').toUpperCase()}
                            />
                        </LocalizationProvider>{' '}
                        {isTablet ? (
                            <Box mr={pxToRem(8)} />
                        ) : (
                            <Box
                                display={'flex'}
                                alignItems={'center'}
                                px={pxToRem(1.5)}
                            >
                                <ArrowRight />
                            </Box>
                        )}
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={'hy-am'}
                        >
                            <DatePicker
                                sx={{ width: '100%' }}
                                disableFuture
                                showDaysOutsideCurrentMonth
                                views={['year', 'month', 'day']}
                                format="DD MMMM YYYY"
                                label={'Դատական ակտի ամսաթիվ'}
                                value={
                                    searchingFields?.courtActDateEnd
                                        ? dayjs(searchingFields.courtActDateEnd)
                                        : null
                                }
                                inputRef={startDateInputRef}
                                onChange={(
                                    newValue: any,
                                    context: FieldChangeHandlerContext<any>,
                                ) => {
                                    newValue &&
                                    context.validationError !== 'invalidDate'
                                        ? setEndDateError(false)
                                        : setEndDateError(true);
                                    if (setSearchingFields) {
                                        setSearchingFields((prevState: any) => {
                                            return {
                                                ...prevState,
                                                courtActDateEnd:
                                                    dayjs(newValue).format(
                                                        'YYYY-MM-DD',
                                                    ),
                                            };
                                        });
                                    }
                                }}
                                slots={{
                                    openPickerIcon: CalendarMonthIcon,
                                }}
                                slotProps={{
                                    textField: {
                                        InputLabelProps: {
                                            margin: 'dense',
                                            error: endDateError,
                                        },
                                    },
                                    actionBar: ({ wrapperVariant }) => ({
                                        actions:
                                            wrapperVariant === 'mobile'
                                                ? ['cancel', 'accept']
                                                : [],
                                    }),
                                }}
                                dayOfWeekFormatter={(
                                    _day: string,
                                    date: Dayjs,
                                ) => dayjs(date).format('dd').toUpperCase()}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </Collapse>
        </Box>
    );
};
export default AdvancedSearch;
