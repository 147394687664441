import * as React from 'react';
import {
    AppBar,
    Box,
    Button,
    Typography,
    Link,
    ListItemButton,
    ListItemText,
    Popper,
    Grow,
    Paper,
    ClickAwayListener,
    MenuList,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { useAppSelector } from 'hooks/redux';
import { selectCurrentUser } from 'store/slices/user/user-slice';

import pxToRem from 'assets/theme/function/px-to-rem';
import colors from 'assets/theme/base/colors';

import headerBackground from 'assets/images/header/header-background.png';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ReactComponent as IconDollar } from 'assets/images/simvol.svg';
import AuthDialog from 'component/authorization/authDialog';
import { useLocation, useNavigate } from 'react-router-dom';
import routes from 'routes/routes';
import { RouteParams } from 'types/routes/route-params';
import { ReactComponent as LogoUniLex } from 'assets/images/header/logo-unilex.svg';
import { useSignOutMutation } from 'store/slices/user/user-auth-api';

interface Props {
    logo?: string;
    pageTitle: string;
}

const Index: React.FC<Props> = (props: Props) => {
    const { logo, pageTitle } = props;

    const [openDialog, setOpenDialog] = React.useState(false);

    const currentUser = useAppSelector(selectCurrentUser);

    const [open, setOpen] = React.useState(false);
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        routePath: string,
    ) => {
        navigate(routePath);
    };
    const [signOutMutation] = useSignOutMutation();
    const handleSignOut = () => {
        const token = localStorage.getItem('refresh_token');
        if (token !== null) {
            signOutMutation({ refresh_token: token });
        } else {
            return;
        }
        navigate('/');
    };
    /* const handleClose = () => {
        setAnchorEl(null);
    };*/

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const navigates = () => {
        navigate('/');
    };

    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    const anchorRef = React.useRef<HTMLButtonElement>(null);
    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current && !open) {
            anchorRef.current?.focus();
        }

        prevOpen.current = open;
    }, [open]);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const isMedia = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <>
            <Box>
                <AppBar position="absolute" sx={{ height: pxToRem(85) }}>
                    <Box
                        display={'flex'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        mx={
                            isMobile
                                ? pxToRem(24)
                                : isTablet
                                  ? pxToRem(35)
                                  : pxToRem(72)
                        }
                        my={pxToRem(12)}
                    >
                        <Box display={'grid'}>
                            <Link
                                variant={'h2'}
                                fontWeight={'bold'}
                                color={colors.white.main}
                                underline={'none'}
                                onClick={() => navigates()}
                            >
                                <LogoUniLex />
                            </Link>

                            <Link
                                variant={isMobile ? 'h6' : 'h4'}
                                color={colors.white.main}
                                underline={'none'}
                                onClick={() => navigates()}
                            >
                                Դատական Տեղեկատվական Համակարգ
                            </Link>
                        </Box>

                        <Box display={'flex'}>
                            {!currentUser ? (
                                <span
                                    onClick={() => {
                                        setOpenDialog(true);
                                    }}
                                />
                            ) : (
                                <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'space-between'}
                                >
                                    <Box ml={pxToRem(20)}>
                                        <Button
                                            ref={anchorRef}
                                            id="composition-button"
                                            aria-controls={
                                                open
                                                    ? 'composition-menu'
                                                    : undefined
                                            }
                                            aria-expanded={
                                                open ? 'true' : undefined
                                            }
                                            aria-haspopup="true"
                                            onClick={handleToggle}
                                        >
                                            Անուն Ազգանուն
                                            <ArrowDropDownIcon
                                                sx={{
                                                    width: pxToRem(18),
                                                    height: pxToRem(18),
                                                }}
                                                fontSize={'large'}
                                            />
                                        </Button>

                                        <Popper
                                            open={open}
                                            anchorEl={anchorRef.current}
                                            role={undefined}
                                            placement="bottom-start"
                                            transition
                                            disablePortal
                                        >
                                            {({
                                                TransitionProps,
                                                placement,
                                            }) => (
                                                <Grow
                                                    {...TransitionProps}
                                                    style={{
                                                        transformOrigin:
                                                            placement ===
                                                            'bottom-start'
                                                                ? 'left top'
                                                                : 'left bottom',
                                                    }}
                                                >
                                                    <Paper>
                                                        <ClickAwayListener
                                                            onClickAway={
                                                                handleClose
                                                            }
                                                        >
                                                            <MenuList
                                                                autoFocusItem={
                                                                    open
                                                                }
                                                                id="composition-menu"
                                                                aria-labelledby="composition-button"
                                                                onKeyDown={
                                                                    handleListKeyDown
                                                                }
                                                            >
                                                                {routes.map(
                                                                    (
                                                                        routeParam: RouteParams,
                                                                        index: number,
                                                                    ) => (
                                                                        <Box
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            {routeParam.rules && (
                                                                                <ListItemButton
                                                                                    selected={
                                                                                        pathname ===
                                                                                        routeParam.path
                                                                                    }
                                                                                    onClick={(
                                                                                        event,
                                                                                    ) =>
                                                                                        handleListItemClick(
                                                                                            event,
                                                                                            routeParam.path,
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <ListItemText
                                                                                        sx={{
                                                                                            display:
                                                                                                'flex',
                                                                                            justifyContent:
                                                                                                'center',
                                                                                        }}
                                                                                        primary={
                                                                                            routeParam.title
                                                                                        }
                                                                                    />
                                                                                </ListItemButton>
                                                                            )}
                                                                        </Box>
                                                                    ),
                                                                )}
                                                                <ListItemButton
                                                                    onClick={() =>
                                                                        handleSignOut()
                                                                    }
                                                                >
                                                                    <ListItemText
                                                                        sx={{
                                                                            textAlign:
                                                                                'center',
                                                                        }}
                                                                    >
                                                                        Ելք
                                                                    </ListItemText>
                                                                </ListItemButton>
                                                            </MenuList>
                                                        </ClickAwayListener>
                                                    </Paper>
                                                </Grow>
                                            )}
                                        </Popper>
                                    </Box>
                                    <Box
                                        ml={pxToRem(20)}
                                        display={'flex'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        height={pxToRem(44)}
                                        paddingRight={pxToRem(8)}
                                        paddingLeft={pxToRem(8)}
                                        sx={{
                                            border: '1px solid rgba(221, 226, 245, 0.70)',
                                            borderRadius: pxToRem(4),
                                        }}
                                    >
                                        <IconDollar />
                                        <Typography
                                            variant={'h5'}
                                            color={colors.white.main}
                                            ml={pxToRem(8)}
                                            fontWeight={400}
                                        >
                                            20.000
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            mt: isMobile
                                ? pxToRem(20)
                                : isTablet
                                  ? pxToRem(60)
                                  : pxToRem(100),
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            color={colors.white.main}
                            variant={isMobile ? 'h4' : isTablet ? 'h2' : 'h1'}
                        >
                            {pageTitle}
                        </Typography>
                    </Box>
                </AppBar>

                <Box
                    sx={{
                        backgroundImage: `url(${logo ?? headerBackground})`,
                        backgroundSize: isMobile
                            ? '307% auto '
                            : isTablet
                              ? '200% auto'
                              : isMedia
                                ? '150% auto'
                                : '100% auto',
                        backgroundRepeat: 'no-repeat',
                        backgroundPositionX: 'center',
                        backgroundPositionY: 'top',
                        minHeight: isMobile
                            ? pxToRem(150)
                            : isTablet
                              ? pxToRem(200)
                              : isMedia
                                ? pxToRem(240)
                                : pxToRem(282),
                    }}
                />
            </Box>

            <AuthDialog openDialog={openDialog} setOpenDialog={setOpenDialog} />
        </>
    );
};
export default Index;
