import boxShadow from 'assets/theme/function/box-shadow';
import colors from 'assets/theme/base/colors';

const { black, grey } = colors;

const boxShadows = {
    xs: boxShadow([0, 0], [20, 0], black.main, 0.07),
    sm: boxShadow([0, 5], [10, 0], grey['600'], 0.12),
    mm: boxShadow([0, 10], [20, 0], black.main, 0.03),
    md: `${boxShadow([0, 4], [6, -1], grey['800'], 0.1)}, ${boxShadow(
        [0, 2],
        [4, -1],
        grey['800'],
        0.4,
    )}`,
    lg: `${boxShadow([0, 10], [15, -3], grey['800'], 0.1)}, ${boxShadow(
        [0, 4],
        [6, -2],
        grey['800'],
        0.05,
    )}`,
    xl: `${boxShadow([0, 20], [25, -5], grey['800'], 0.1)}, ${boxShadow(
        [0, 10],
        [10, -5],
        grey['800'],
        0.04,
    )}`,
    xxl: boxShadow([0, 20], [27, 0], grey['900'], 0.05),
    inset: boxShadow([0, 1], [2, 0], grey['800'], 0.075, 'inset'),
    colored: {
        primary: `${boxShadow([0, 4], [20, 0], grey['800'], 0.14)}, ${boxShadow(
            [0, 7],
            [10, -5],
            grey['800'],
            0.4,
        )}`,
        secondary: `${boxShadow(
            [0, 4],
            [20, 0],
            grey['800'],
            0.14,
        )}, ${boxShadow([0, 7], [10, -5], grey['700'], 0.4)}`,
        info: `${boxShadow([0, 4], [20, 0], grey['800'], 0.14)}, ${boxShadow(
            [0, 7],
            [10, -5],
            grey['700'],
            0.4,
        )}`,
        success: `${boxShadow([0, 4], [20, 0], grey['800'], 0.14)}, ${boxShadow(
            [0, 7],
            [10, -5],
            grey['600'],
            0.4,
        )}`,
        warning: `${boxShadow([0, 4], [20, 0], grey['800'], 0.14)}, ${boxShadow(
            [0, 7],
            [10, -5],
            grey['600'],
            0.4,
        )}`,
        error: `${boxShadow([0, 4], [20, 0], grey['800'], 0.14)}, ${boxShadow(
            [0, 7],
            [10, -5],
            grey['600'],
            0.4,
        )}`,
        light: `${boxShadow([0, 4], [0, 0], grey['400'], 0.2)}, ${boxShadow(
            [0, 7],
            [7, -5],
            grey['300'],
            0.4,
        )}`,
        dark: `${boxShadow([0, 2], [3, 3], grey['800'], 0.2)}, ${boxShadow(
            [0, 2],
            [3, 0],
            grey['600'],
            0.4,
        )}`,
    },

    navbarBoxShadow: `${boxShadow(
        [2, 2],
        [1, 1],
        grey['500'],
        1,
        'inset',
    )}, ${boxShadow([0, 20], [27, 0], grey['800'], 0.05)}`,
    sliderBoxShadow: {
        thumb: boxShadow([0, 1], [13, 0], grey['800'], 0.2),
    },
    tabsBoxShadow: {
        indicator: boxShadow([0, 1], [5, 1], grey['600'], 1),
    },
};

export default boxShadows;
