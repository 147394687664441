import pxToRem from 'assets/theme/function/px-to-rem';
import colors from 'assets/theme/base/colors';

const tableHead = {
    styleOverrides: {
        root: {
            height: pxToRem(50),
            backgroundColor: colors.info.main,
        },
    },
};

export default tableHead;
