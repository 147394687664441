const breakpoints = {
    values: {
        xs: 0,
        sm: 720,
        md: 1024,
        lg: 1500,
        xl: 1890,
    },
};

export default breakpoints;
