import React from 'react';
import {
    Box,
    Tabs,
    Tab,
    useTheme,
    useMediaQuery,
    Autocomplete,
    TextField,
} from '@mui/material';
import pxToRem from 'assets/theme/function/px-to-rem';
import borders from 'assets/theme/base/borders';
import CivilCase from 'pages/case-search/case-type-filter/civil-case';
import CriminalCase from 'pages/case-search/case-type-filter/criminal-case';
import { CasesTypeRequest } from 'types/cases/cases';
import colors from 'assets/theme/base/colors';
import { initialMainFilterData } from 'pages/case-search/index';

interface Props {
    caseType: string;
    setCaseType: React.Dispatch<React.SetStateAction<string>>;
    setSearchingFields: React.Dispatch<
        React.SetStateAction<CasesTypeRequest | undefined>
    >;
    searchingFields?: CasesTypeRequest;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: string;
    value: string;
}

const caseList = [
    { label: 'Քաղաքացիական', value: 'civil' },
    { label: 'Քրեական', value: 'criminal' },
    { label: 'Վարչական', value: 'administrative' },
    { label: 'Վճարման կարգադրություն', value: 'pay_order' },
    { label: 'Սնանկության', value: 'bankruptcy' },
    { label: 'Հակակոռուպցիոն քաղաքացիական', value: 'anticoruption_civil_case' },
    { label: 'Հակակոռուպցիոն քրեական', value: 'anticoruption_criminal_case' },
];

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </Box>
    );
}

const CaseTypeFilter: React.FC<Props> = ({
    caseType,
    setCaseType,
    setSearchingFields,
    searchingFields,
}) => {
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChange = (event: React.SyntheticEvent, newCaseType: string) => {
        setCaseType(newCaseType);
        setSearchingFields(initialMainFilterData);
    };

    const handleAutocompleteChange = (event: any, newValue: any) => {
        setCaseType(newValue?.value || '');
    };

    const defaultValue =
        caseList.find((caseItem) => caseItem.value === caseType) || null;

    return (
        <Box mt={isMobile ? pxToRem(0) : isTablet ? pxToRem(0) : pxToRem(20)}>
            {isMobile || isTablet ? (
                <Box p={pxToRem(20)} bgcolor={colors.info.main}>
                    <Autocomplete
                        fullWidth
                        defaultValue={defaultValue}
                        disablePortal
                        id="combo-box-demo"
                        options={caseList}
                        renderInput={(params) => (
                            <TextField {...params} label="Ընտրել բաժինը" />
                        )}
                        onChange={handleAutocompleteChange}
                    />
                </Box>
            ) : (
                <Tabs
                    sx={{
                        mx: isMobile
                            ? pxToRem(24)
                            : isTablet
                              ? pxToRem(35)
                              : pxToRem(72),
                    }}
                    value={caseType}
                    variant={'fullWidth'}
                    onChange={handleChange}
                >
                    {caseList.map((caseItem) => (
                        <Tab
                            key={caseItem.value}
                            label={caseItem.label}
                            value={caseItem.value}
                        />
                    ))}
                </Tabs>
            )}

            <Box
                mx={
                    isMobile
                        ? pxToRem(24)
                        : isTablet
                          ? pxToRem(35)
                          : pxToRem(72)
                }
                borderLeft={
                    isTablet
                        ? 'none'
                        : `${borders.borderWidth[1]} solid ${borders.borderColor}`
                }
                borderRight={
                    isTablet
                        ? 'none'
                        : `${borders.borderWidth[1]} solid ${borders.borderColor}`
                }
                borderBottom={
                    isTablet
                        ? 'none'
                        : `${borders.borderWidth[1]} solid ${borders.borderColor}`
                }
                borderRadius={`0 0 ${borders.borderRadius.md} ${borders.borderRadius.md}`}
                paddingTop={pxToRem(24)}
                paddingLeft={isTablet ? pxToRem(0) : pxToRem(24)}
                paddingRight={isTablet ? pxToRem(0) : pxToRem(24)}
                paddingBottom={isTablet ? pxToRem(0) : pxToRem(16)}
            >
                <TabPanel value={caseType} index={'civil'}>
                    <CivilCase
                        setSearchingFields={setSearchingFields}
                        searchingFields={searchingFields}
                        caseType={caseType}
                    />
                </TabPanel>
                <TabPanel value={caseType} index={'criminal'}>
                    <CriminalCase
                        setSearchingFields={setSearchingFields}
                        searchingFields={searchingFields}
                        caseType={caseType}
                    />
                </TabPanel>
                <TabPanel value={caseType} index={'administrative'}>
                    <CivilCase
                        setSearchingFields={setSearchingFields}
                        searchingFields={searchingFields}
                        caseType={caseType}
                    />
                </TabPanel>
                <TabPanel value={caseType} index={'pay_order'}>
                    <CivilCase
                        setSearchingFields={setSearchingFields}
                        searchingFields={searchingFields}
                        caseType={caseType}
                    />
                </TabPanel>
                <TabPanel value={caseType} index={'bankruptcy'}>
                    <CivilCase
                        setSearchingFields={setSearchingFields}
                        searchingFields={searchingFields}
                        caseType={caseType}
                    />
                </TabPanel>
                <TabPanel value={caseType} index={'anticoruption_civil_case'}>
                    <CivilCase
                        setSearchingFields={setSearchingFields}
                        searchingFields={searchingFields}
                        caseType={caseType}
                    />
                </TabPanel>
                <TabPanel
                    value={caseType}
                    index={'anticoruption_criminal_case'}
                >
                    <CriminalCase
                        setSearchingFields={setSearchingFields}
                        searchingFields={searchingFields}
                        caseType={caseType}
                    />
                </TabPanel>
            </Box>
        </Box>
    );
};

export default CaseTypeFilter;
