const link = {
    defaultProps: {
        //underline: 'none',
        color: 'text.secondary',
    },
    styleOverrides: {
        root: {
            cursor: 'pointer',
        },
    },
};

export default link;
