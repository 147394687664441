import * as React from 'react';
import {
    Box,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import pxToRem from 'assets/theme/function/px-to-rem';
import { ReactComponent as InfoIcon } from 'assets/images/inputIcon/infoIcon.svg';
import CaseTypeFilterAction from 'pages/case-search/case-type-filter/case-type-filter-action';
import Decisions, { decisionSearchTypes } from 'component/decisions';
import { CasesTypeRequest } from 'types/cases/cases';
import AdvancedSearchShort from 'pages/case-search/case-type-filter/advanced-search/advanced-search-short';
import AdvancedSearch from 'pages/case-search/case-type-filter/advanced-search/advanced-search';

interface Props {
    caseType: string;
    setSearchingFields: React.Dispatch<
        React.SetStateAction<CasesTypeRequest | undefined>
    >;
    searchingFields?: CasesTypeRequest;
}
const decisions: React.RefObject<HTMLInputElement>[] = [];
const decisionSearchType: React.RefObject<HTMLInputElement>[] = [];

const CriminalCase: React.FC<Props> = (props: Props) => {
    const { caseType, setSearchingFields, searchingFields } = props;
    const [addedComponents, setAddedComponents] = React.useState<{
        [key: string]: JSX.Element;
    }>({});

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    const [keyCounter, setKeyCounter] = React.useState<number>(0);

    const handleClickDecisions = async () => {
        const newDecisions: string[] = [];
        const newDecisionSearchType: string[] = [];
        if (decisions.length >= 1) {
            decisions.forEach((item) => {
                if (item?.current?.value) {
                    newDecisions.push(item.current.value);
                }
            });
        }
        if (decisionSearchType.length >= 1) {
            decisionSearchType.forEach((item) => {
                const value = findDecisionTypeValue(item?.current?.value);
                if (value) {
                    newDecisionSearchType.push(value.value);
                }
            });
        }

        await setSearchingFields((prevState) => ({
            ...prevState,
            decisions: newDecisions,
            decisionSearchType: newDecisionSearchType,
        }));
    };

    const findDecisionTypeValue = (title?: string) => {
        return decisionSearchTypes.find((element) => element.label === title);
    };

    const resetComponent = (key?: string) => {
        if (key === undefined) {
            return;
        }
        setAddedComponents((prevComponents) => {
            const updatedComponents = { ...prevComponents };
            if (updatedComponents[key]) {
                delete updatedComponents[key];
            }
            return updatedComponents;
        });
    };

    const addComponent = () => {
        const newKey = `decision-${keyCounter}`;
        setAddedComponents((prevComponents) => ({
            ...prevComponents,
            [newKey]: (
                <Decisions
                    key={newKey}
                    decisionKey={newKey}
                    addComponent={addComponent}
                    resetComponent={() => resetComponent(newKey)}
                    addedComponentsPlus={false}
                    handleClickDecisions={handleClickDecisions}
                />
            ),
        }));
        setKeyCounter((prevCounter) => prevCounter + 1);
    };
    return (
        <Box>
            <Grid container spacing={1}>
                <Grid item xs={12} md={6} lg={4} sx={{ alignSelf: 'end' }}>
                    <TextField
                        fullWidth
                        label={'Գործի համար'}
                        name={'caseNumber'}
                        value={searchingFields?.caseNumber || ''}
                        onChange={(e) => {
                            const { name, value } = e.target;
                            if (setSearchingFields) {
                                setSearchingFields((prevState: any) => ({
                                    ...prevState,
                                    [name]: value,
                                }));
                            }
                        }}
                        InputProps={{
                            'aria-label': 'password',
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        /*onClick={() =>
                                                setShowPass(
                                                    (current) =>
                                                        !current,
                                                )
                                            }*/
                                        onMouseDown={(e) => e.preventDefault()}
                                        edge="end"
                                    >
                                        <InfoIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    <TextField
                        fullWidth
                        label={'Բովանդակություն'}
                        name={'claim'}
                        value={searchingFields?.claim || ''}
                        onChange={(e) => {
                            const { name, value } = e.target;
                            if (setSearchingFields) {
                                setSearchingFields((prevState: any) => ({
                                    ...prevState,
                                    [name]: value,
                                }));
                            }
                        }}
                        sx={{ mt: isMobile ? pxToRem(8) : pxToRem(16) }}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                    <Typography variant={isMobile ? 'h6' : 'h5'}>
                        ԱՄԲԱՍՏԱՆՅԱԼ
                    </Typography>

                    <TextField
                        fullWidth
                        label={'Անուն'}
                        name={'claimantApplicantFirstName'}
                        value={
                            searchingFields?.claimantApplicantFirstName || ''
                        }
                        onChange={(e) => {
                            const { name, value } = e.target;
                            if (setSearchingFields) {
                                setSearchingFields((prevState: any) => ({
                                    ...prevState,
                                    [name]: value,
                                }));
                            }
                        }}
                        sx={{
                            mt: isTablet ? pxToRem(8) : pxToRem(16),
                        }}
                    />

                    <TextField
                        fullWidth
                        label={'Ազգանուն'}
                        name={'claimantApplicantLastName'}
                        value={searchingFields?.claimantApplicantLastName || ''}
                        onChange={(e) => {
                            const { name, value } = e.target;
                            if (setSearchingFields) {
                                setSearchingFields((prevState: any) => ({
                                    ...prevState,
                                    [name]: value,
                                }));
                            }
                        }}
                        sx={{ mt: isMobile ? pxToRem(8) : pxToRem(16) }}
                    />
                </Grid>

                {isMobile ? '' : <Grid item xs={12} md={6} lg={4} />}

                {caseType !== 'pay_order' && (
                    <>
                        <Decisions
                            key={'key'}
                            addComponent={addComponent}
                            addedComponentsPlus={true}
                            resetComponent={resetComponent}
                            decisions={decisions}
                            decisionSearchType={decisionSearchType}
                            handleClickDecisions={handleClickDecisions}
                        />
                        {Object.values(addedComponents).map((decision) => (
                            <Box
                                display={'flex'}
                                width={'100%'}
                                key={decision.key}
                            >
                                {decision}
                            </Box>
                        ))}
                    </>
                )}
            </Grid>
            {caseType === 'pay_order' ? (
                <AdvancedSearchShort
                    setSearchingFields={setSearchingFields}
                    searchingFields={searchingFields}
                />
            ) : (
                <AdvancedSearch
                    setSearchingFields={setSearchingFields}
                    searchingFields={searchingFields}
                />
            )}
            <CaseTypeFilterAction />
        </Box>
    );
};
export default CriminalCase;
