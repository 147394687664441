import boxShadows from 'assets/theme/base/box-shadows';
import typography from 'assets/theme/base/typography';
import colors from 'assets/theme/base/colors';
import borders from 'assets/theme/base/borders';
import pxToRem from 'assets/theme/function/px-to-rem';

const { xs } = boxShadows;
const { size } = typography;
const { white, text, secondary } = colors;
const { borderRadius } = borders;

const autoComplete = {
    styleOverrides: {
        inputRoot: {
            padding: `${pxToRem(0)} 0 ${pxToRem(2)} ${pxToRem(8)}`,
            minHeight: pxToRem(37),
            height: pxToRem(40),
        },

        popper: {
            boxShadow: xs,
            borderRadius: borderRadius.xl,
        },

        paper: {
            boxShadow: 'none',
            backgroundColor: white.main,
            borderRadius: borderRadius.xl,
        },

        option: {
            //padding: `${pxToRem(4.8)} ${pxToRem(16)}`,
            fontSize: size.sm,
            color: text.primary,

            '&:hover, &:focus, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus':
                {
                    backgroundColor: colors.info.main,
                },

            '&[aria-selected="true"]': {
                color: secondary.main,
            },
        },
        /*'& .MuiButtonBase-root-MuiChip-root': {
            height: pxToRem(20),
        },*/
        tag: {
            height: pxToRem(25),
            fontSize: size.xs,

            '& .MuiChip-label': {
                lineHeight: 1.2,
            },
        },

        /*noOptions: {
            fontSize: size.sm,
            //color: text.main,
        },

        groupLabel: {
            //color: dark.main,
        },

        loading: {
            fontSize: size.sm,
            //color: text.main,
        },

        tag: {
            display: 'flex',
            alignItems: 'center',
            height: 'auto',
            padding: pxToRem(4),
            //backgroundColor: gradients.dark.state,
            //color: white.main,

            '& .MuiChip-label': {
                lineHeight: 1.2,
                //padding: `0 ${pxToRem(10)} 0 ${pxToRem(4)}`,
            },

            '& .MuiSvgIcon-root, & .MuiSvgIcon-root:hover, & .MuiSvgIcon-root:focus':
                {
                    //color: white.main,
                    marginRight: 0,
                },
        },*/
    },
};

export default autoComplete;
