import colors from 'assets/theme/base/colors';
import typography from 'assets/theme/base/typography';
import pxToRem from 'assets/theme/function/px-to-rem';

const { primary, secondary } = colors;
const { size } = typography;

const buttonText = {
    base: {
        backgroundColor: 'transparent',
        minHeight: pxToRem(44),
        color: primary.main,
        boxShadow: 'none',
        padding: `${pxToRem(10)} ${pxToRem(24)}`,

        '&:hover': {
            boxShadow: 'none',
        },

        '&:focus': {
            boxShadow: 'none',
        },

        '&:active, &:active:focus, &:active:hover': {
            opacity: 1,
            boxShadow: 'none',
        },

        '&:disabled': {
            boxShadow: 'none',
        },

        '& .material-icon, .material-icons-round, svg': {
            fontSize: `${pxToRem(16)}`,
        },
    },

    small: {
        minHeight: pxToRem(32),
        padding: `${pxToRem(6)} ${pxToRem(16)}`,
        fontSize: size.xs,

        '& .material-icon, .material-icons-round, svg': {
            fontSize: `${pxToRem(12)}`,
        },
    },

    large: {
        minHeight: pxToRem(47),
        padding: `${pxToRem(12)} ${pxToRem(28)}`,
        fontSize: size.sm,

        '& .material-icon, .material-icons-round, svg': {
            fontSize: `${pxToRem(22)}`,
        },
    },

    primary: {
        color: colors.white.main,
        '&:hover': {
            color: colors.white.main,
        },

        '&:focus:not(:hover)': {
            color: colors.white.main,
            boxShadow: 'none',
        },
    },
    success: {
        color: colors.grey['900'],

        '&:hover': {
            color: colors.grey['500'],
        },

        '&:focus:not(:hover)': {
            color: colors.grey['500'],
            boxShadow: 'none',
        },
    },

    secondary: {
        color: primary.main,

        '&:hover': {
            color: primary.main,
        },

        '&:focus:not(:hover)': {
            color: secondary.light,
            boxShadow: 'none',
        },
    },
};

export default buttonText;
