import { TableMetaData } from 'types/common/flexible-table';

export const civilCaseTableMetaData: TableMetaData[] = [
    {
        key: 'current_number',
        title: 'Հ/Հ',
    },
    {
        key: 'caseNumber',
        title: 'Գործի համար',
    },
    {
        key: 'claimantApplicant',
        title: 'Հայցվոր',
        textMaxLength: 30,
    },
    {
        key: 'respondent',
        title: 'Պատասխանող',
    },
    {
        key: 'judge',
        title: 'Դատավոր',
    },
    {
        key: 'case_show',
        title: '',
    },
];

export const paymentOrderCaseTableMetaData: TableMetaData[] = [
    {
        key: 'current_number',
        title: 'Հ/Հ',
    },
    {
        key: 'caseNumber',
        title: 'Գործի համար',
    },
    {
        key: 'claimantApplicant',
        title: 'Դիմող',
    },
    {
        key: 'respondent',
        title: 'Պատասխանող',
    },
    {
        key: 'judge',
        title: 'Դատավոր',
    },
    {
        key: 'case_show',
        title: '',
    },
];

export const bankruptcyCaseTableMetaData: TableMetaData[] = [
    {
        key: 'current_number',
        title: 'Հ/Հ',
    },
    {
        key: 'caseNumber',
        title: 'Գործի համար',
    },
    {
        key: 'claimantApplicant',
        title: 'Դիմումատու',
    },
    {
        key: 'respondent',
        title: 'Պարտապան',
    },
    {
        key: 'judge',
        title: 'Դատավոր',
    },
    {
        key: 'case_show',
        title: '',
    },
];

export const criminalCaseTableMetaData: TableMetaData[] = [
    {
        key: 'current_number',
        title: 'Հ/Հ',
    },
    {
        key: 'caseNumber',
        title: 'Գործի համար',
    },
    {
        key: 'defendant',
        title: 'Ամբաստանյալ',
    },
    {
        key: 'judge',
        title: 'Դատավոր',
    },
    {
        key: 'case_show',
        title: '',
    },
];
