import pxToRem from 'assets/theme/function/px-to-rem';
import colors from 'assets/theme/base/colors';

const listItemButton = {
    styleOverrides: {
        root: {
            paddingRight: pxToRem(0),
            paddingLeft: pxToRem(0),
            '&.Mui-selected': {
                color: colors.text.secondary,
                fontWeight: 700,
                backgroundColor: 'transparent',
            },
        },
    },
};

export default listItemButton;
