import { apiSlice } from '../api/api-slice';
import serializeObjectToURI from 'utils/serializer';
import {
    TransactionHistoryListInfo,
    TransactionHistoryListRequest,
} from 'types/transaction-history/transaction-history';

export const transactionHistoryListApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getTransactionHistory: builder.query<
            TransactionHistoryListInfo,
            TransactionHistoryListRequest
        >({
            query: (
                transactionHistoryListRequest: TransactionHistoryListRequest,
            ) => ({
                url: serializeObjectToURI(
                    transactionHistoryListRequest,
                    'app/transaction-history?',
                ),
                method: 'GET',
            }),
        }),
    }),
});

export const { useLazyGetTransactionHistoryQuery } = transactionHistoryListApi;
