import * as React from 'react';
import {
    Autocomplete,
    Box,
    Grid,
    TextField,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { ReactComponent as AddIcon } from 'assets/images/icon-add.svg';
import { ReactComponent as ResetComponent } from 'assets/images/icon-minus.svg';
import pxToRem from 'assets/theme/function/px-to-rem';
import { useRef, useEffect, memo } from 'react';
import { currentCaseResetState } from 'store/slices/cases/case-search-slice';
import { useAppSelector } from 'hooks/redux';
import { Dictionary } from 'types/cases/case-full-data';

export const decisionSearchTypes: Dictionary[] = [
    { label: 'Բոլոր բառերով', value: 'allWords' },
    { label: 'Որևէ բառերով', value: 'anyWords' },
    { label: 'Ստույգ տեքստով', value: 'exactText' },
    { label: 'Բացառելով', value: 'excluding' },
];

interface Props {
    decisionKey?: string;
    addComponent: () => void;
    addedComponentsPlus: boolean;
    resetComponent: (key: string | undefined) => void;
    decisions?: React.Ref<HTMLInputElement>[];
    decisionSearchType?: React.Ref<HTMLElement>[];
    handleClickDecisions: () => void;
}

const Decisions: React.FC<Props> = memo((props: Props) => {
    const textFieldRef = useRef<HTMLInputElement>(null);
    const autocompleteRef = useRef<HTMLElement>(null);
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    const {
        decisionKey,
        addComponent,
        addedComponentsPlus,
        resetComponent,
        decisions,
        decisionSearchType,
        handleClickDecisions,
    } = props;
    const { cancelState } = useAppSelector(currentCaseResetState);

    useEffect(() => {
        if (cancelState) {
            if (textFieldRef.current) {
                textFieldRef.current.value = '';
            }
        }
    }, [cancelState]);

    useEffect(() => {
        decisions?.push(textFieldRef);
        decisionSearchType?.push(autocompleteRef);
    }, []);

    return (
        <>
            <Grid
                container
                spacing={1}
                marginLeft={pxToRem(2)}
                mt={isTablet ? pxToRem(0) : pxToRem(30)}
            >
                <Grid item xs={12} sm={12} md={8}>
                    <TextField
                        fullWidth
                        label={'Վճռի բովանդակություն'}
                        name={'decisions'}
                        inputRef={textFieldRef}
                        onChange={handleClickDecisions}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                    <Box display={'flex'} mt={isTablet ? pxToRem(10) : ''}>
                        <Autocomplete
                            fullWidth
                            disablePortal
                            options={decisionSearchTypes}
                            defaultValue={decisionSearchTypes?.[0]}
                            getOptionLabel={(option) => `${option.label}`}
                            renderInput={(params) => (
                                <TextField
                                    inputRef={autocompleteRef}
                                    {...params}
                                    label={'Մեթոդ'}
                                    onChange={handleClickDecisions}
                                />
                            )}
                            onChange={handleClickDecisions}
                        />
                        <Box ml={pxToRem(4)}>
                            {addedComponentsPlus ? (
                                <Box
                                    onClick={addComponent}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <AddIcon />
                                </Box>
                            ) : (
                                <Box
                                    onClick={() => resetComponent(decisionKey)}
                                    sx={{
                                        cursor: 'pointer',
                                    }}
                                >
                                    <ResetComponent
                                        style={{ borderRadius: pxToRem(6) }}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
});

export default Decisions;
