import React from 'react';
import { Box } from '@mui/material';
import Header from 'component/header';
import Footer from 'component/footer';

interface LayoutProps {
    logo?: string;
    pageTitle: string;
    children: React.ReactNode;
}

const ProtectedLayout = (props: LayoutProps) => {
    const { children, logo, pageTitle } = props;

    return (
        <Box>
            <Header pageTitle={pageTitle} logo={logo} />
            <Box>{children}</Box>
            <Footer />
        </Box>
    );
};

export default ProtectedLayout;
