import colors from 'assets/theme/base/colors';
import pxToRem from 'assets/theme/function/px-to-rem';

const dialogTitle = {
    styleOverrides: {
        root: {
            color: colors.text.primary,
            paddingBottom: pxToRem(0),
            paddingTop: pxToRem(40),
            paddingLeft: pxToRem(50),
            paddingRight: pxToRem(50),
        },
    },
};

export default dialogTitle;
