import typography from 'assets/theme/base/typography';

const { fontWeightRegular, size } = typography;

const listItemText = {
    styleOverrides: {
        root: {
            marginTop: 0,
            marginBottom: 0,
        },
        primary: {
            fontSize: size.md,
            fontWeight: fontWeightRegular,
        },
    },
};

export default listItemText;
