import React, { useEffect } from 'react';
import Login from '../login';
import ResetSecreting from '../reset-secreting';
import Register from '../register';
import SecurityCode from '../security-code';
import ConfirmEmail from 'component/authorization/register/confirm- email';
import { useNavigate, useParams } from 'react-router-dom';
import { useEmailConfirmMutation } from 'store/slices/user/user-reset-password';
import PasswordResetSuccessfully from 'component/authorization/successfully-reset';
import RegisterResetSuccessfully from 'component/authorization/successfully-reset-registerr';
import { openSnackbar } from 'store/slices/snackbar/snackbar-slice';
import { useAppDispatch } from 'hooks/redux';

interface Props {
    openDialog: boolean;
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

const AuthDialog: React.FC<Props> = (props) => {
    const { openDialog, setOpenDialog } = props;
    const [openRegisterDialog, setOpenRegisterDialog] = React.useState(false);
    const [openSecretingDialog, setOpenSecretingDialog] = React.useState(false);
    const [openConfirmEmailDialog, setOpenConfirmEmailDialog] =
        React.useState(false);
    const [openSecurityCodeDialog, setOpenSecurityCodeDialog] =
        React.useState(false);
    const [openSuccessfullyResetDialog, setOpenSuccessfullyResetDialog] =
        React.useState(false);
    const [
        openEmailSuccessfullyResetDialog,
        setOpenEmailSuccessfullyResetDialog,
    ] = React.useState(false);
    const { resetPasswordToken } = useParams<{ resetPasswordToken: string }>();
    const { emailConfirmation } = useParams<{ emailConfirmation: string }>();

    const [
        emailConfirmations,
        { isSuccess: emailIsSuccess, error: emailError },
    ] = useEmailConfirmMutation();
    const [
        resetPassword,
        { isSuccess: resetPasswordIsSuccess, error: resetPasswordError },
    ] = useEmailConfirmMutation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (emailConfirmation) {
            emailConfirmations({ verificationCode: emailConfirmation });
        }
        if (resetPasswordToken) {
            resetPassword({ verificationCode: resetPasswordToken });
        }
    }, [emailConfirmation, resetPasswordToken]);
    useEffect(() => {
        if (emailIsSuccess) {
            navigate('/');
            setOpenEmailSuccessfullyResetDialog(true);
        }
        if (resetPasswordIsSuccess) {
            navigate('/');
            setOpenSuccessfullyResetDialog(true);
        }
    }, [emailIsSuccess, resetPasswordIsSuccess]);

    useEffect(() => {
        if (emailError && 'data' in emailError) {
            dispatch(
                openSnackbar({
                    message: emailError.data.message /* ||
                        resetPasswordError?.data.message*/,
                    severity: 'error',
                    open: true,
                    position: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                }),
            );
        }
    }, [emailError, resetPasswordError]);

    return (
        <>
            <Login
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                setOpenRegisterDialog={setOpenRegisterDialog}
                setOpenSecretingDialog={setOpenSecretingDialog}
            />
            <Register
                setOpenDialog={setOpenDialog}
                openRegisterDialog={openRegisterDialog}
                setOpenRegisterDialog={setOpenRegisterDialog}
                setOpenConfirmEmailDialog={setOpenConfirmEmailDialog}
            />
            <ResetSecreting
                openSecretingDialog={openSecretingDialog}
                setOpenSecretingDialog={setOpenSecretingDialog}
                setOpenConfirmEmailDialog={setOpenConfirmEmailDialog}
            />
            <SecurityCode
                openSecurityCodeDialog={openSecurityCodeDialog}
                setOpenSecurityCodeDialog={setOpenSecurityCodeDialog}
                setOpenSuccessfullyResetDialog={setOpenSuccessfullyResetDialog}
            />
            <PasswordResetSuccessfully
                setOpenSuccessfullyResetDialog={setOpenSuccessfullyResetDialog}
                openSuccessfullyResetDialog={openSuccessfullyResetDialog}
            />
            <RegisterResetSuccessfully
                setOpenEmailSuccessfullyResetDialog={
                    setOpenEmailSuccessfullyResetDialog
                }
                openEmailSuccessfullyResetDialog={
                    openEmailSuccessfullyResetDialog
                }
            />
            <ConfirmEmail
                openConfirmEmailDialog={openConfirmEmailDialog}
                setOpenConfirmEmailDialog={setOpenConfirmEmailDialog}
            />
        </>
    );
};
export default AuthDialog;
