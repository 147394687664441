import * as React from 'react';
import {
    Autocomplete,
    Box,
    Button,
    Grid,
    TextField,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import pxToRem from 'assets/theme/function/px-to-rem';
import CurrentUserMenu from 'layouts/protected/personal-nu';
import colors from 'assets/theme/base/colors';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ReactComponent as CalendarMonthIcon } from 'assets/images/inputIcon/calendarIcon.svg';
import dayjs, { Dayjs } from 'dayjs';
import {
    usePersonalDetailConfirmMutation,
    usePersonalInfoQuery,
} from 'store/slices/user/user-personal-info';
import {
    PersonalInfo,
    PersonalInfoRequest,
} from 'types/personal-info/personal-info';
import { FieldChangeHandlerContext } from '@mui/x-date-pickers/internals';
import { openSnackbar } from 'store/slices/snackbar/snackbar-slice';
import { useAppDispatch } from 'hooks/redux';

const genderList = [
    { label: 'Իգական', value: 'female' },
    { label: 'Արական', value: 'male' },
];

const PersonalInformation: React.FC = () => {
    const [personalInfo, setPersonalInfo] = useState<PersonalInfoRequest>();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const birthDateInputRef = useRef<HTMLInputElement>(null);
    const [startDateError, setStartDateError] = useState<boolean>(false);
    const personalInfoQuery = usePersonalInfoQuery();
    const [personalDetails, { error, isError }] =
        usePersonalDetailConfirmMutation();
    const dispatch = useAppDispatch();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    const validateFilterData = (): boolean => {
        let isValid = true;
        let errorText = '';

        if (
            personalInfo?.firstName == '' ||
            personalInfo?.firstName == 'Invalid Date'
        ) {
            isValid = false;
            errorText = 'Дата периода не заполнена';
        }

        if (
            personalInfo?.lastName == '' ||
            personalInfo?.lastName == 'Invalid Date'
        ) {
            isValid = false;
            errorText = 'Дата периода не заполнена';
        }

        if (
            personalInfo?.phone == '' ||
            personalInfo?.phone == 'Invalid Date'
        ) {
            isValid = false;
            errorText = 'Дата периода не заполнена';
        }

        if (
            personalInfo?.gender == '' ||
            personalInfo?.gender == 'Invalid Date'
        ) {
            isValid = false;
            errorText = 'Дата периода не заполнена';
        }

        if (!isValid) {
            dispatch(
                openSnackbar({
                    message: errorText,
                    severity: 'error',
                    open: true,
                    position: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                }),
            );
        }

        return isValid;
    };

    useEffect(() => {
        setPersonalInfo(personalInfoQuery.data);
    }, [personalInfoQuery.isSuccess]);

    //const isPressed = useKeyPress({ targetKey: 'Enter' });

    const handleFilterDataPerson = async () => {
        if (validateFilterData() && personalInfo) {
            await personalDetails(personalInfo);
            personalInfoQuery.refetch();
        } else {
            errorMessage;
        }
    };

    useEffect(() => {
        if (error && 'data' in error) {
            setErrorMessage(error?.data.message);
        }
    }, [isError]);

    return (
        <>
            <Grid container spacing={0}>
                <Grid item xs={12} md={1.8} lg={1.8} bgcolor={colors.info.main}>
                    <Box bgcolor={'transparent'}>
                        <CurrentUserMenu />
                    </Box>
                </Grid>
                {personalInfoQuery?.data && (
                    <Grid item xs={12} md={10.2} lg={10.2}>
                        <Box
                            ml={pxToRem(32)}
                            mr={
                                isMobile
                                    ? pxToRem(24)
                                    : isTablet
                                      ? pxToRem(35)
                                      : pxToRem(72)
                            }
                            mt={pxToRem(32)}
                        >
                            <Grid
                                container
                                columnSpacing={{ xs: 2, sm: 2, md: 3 }}
                                mb={pxToRem(19)}
                            >
                                <Grid item xs={12} md={6} lg={6}>
                                    <TextField
                                        fullWidth
                                        defaultValue={
                                            personalInfoQuery.data?.firstName
                                        }
                                        label="Անուն"
                                        type="name"
                                        name="name"
                                        aria-describedby="nameTextHelper"
                                        inputProps={{
                                            'aria-label': 'name',
                                        }}
                                        onChange={(e) => {
                                            const { name, value } = e.target;
                                            setPersonalInfo(
                                                (
                                                    prevState:
                                                        | PersonalInfo
                                                        | undefined,
                                                ) => ({
                                                    ...prevState,
                                                    [name]: value,
                                                    firstName: value,
                                                }),
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <TextField
                                        defaultValue={
                                            personalInfoQuery.data?.phone
                                        }
                                        fullWidth
                                        label="Հեռախոսահամար"
                                        type="phone"
                                        name="phone"
                                        aria-describedby="phoneTextHelper"
                                        inputProps={{
                                            'aria-label': 'phone',
                                        }}
                                        onChange={(e) => {
                                            const { name, value } = e.target;
                                            setPersonalInfo(
                                                (
                                                    prevState:
                                                        | PersonalInfo
                                                        | undefined,
                                                ) => ({
                                                    ...prevState,
                                                    [name]: value,
                                                    phone: value,
                                                }),
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                rowSpacing={2}
                                columnSpacing={{ xs: 2, sm: 2, md: 3 }}
                                mb={pxToRem(19)}
                            >
                                <Grid item xs={12} md={6} lg={6}>
                                    <TextField
                                        defaultValue={
                                            personalInfoQuery.data?.lastName
                                        }
                                        fullWidth
                                        label="Ազգանուն"
                                        type="lastName"
                                        name="lastName"
                                        aria-describedby="lastNameTextHelper"
                                        inputProps={{
                                            'aria-label': 'lastName',
                                        }}
                                        onChange={(e) => {
                                            const { name, value } = e.target;
                                            setPersonalInfo(
                                                (
                                                    prevState:
                                                        | PersonalInfo
                                                        | undefined,
                                                ) => ({
                                                    ...prevState,
                                                    [name]: value,
                                                    lastName: value,
                                                }),
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <Autocomplete
                                        fullWidth
                                        disablePortal
                                        id="combo-box-demo"
                                        value={
                                            genderList
                                                ? genderList.filter(
                                                      (filterVal) =>
                                                          personalInfoQuery.data
                                                              ?.gender ===
                                                          filterVal.value,
                                                  )[0]
                                                : null
                                        }
                                        options={genderList}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Սեռ"
                                            />
                                        )}
                                        onChange={(e, value) => {
                                            setPersonalInfo((prevState) => ({
                                                ...prevState,
                                                gender: value?.value,
                                            }));
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                rowSpacing={2}
                                columnSpacing={{ xs: 2, sm: 2, md: 3 }}
                            >
                                <Grid item xs={12} md={6} lg={6}>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale={'hy-am'}
                                    >
                                        <DatePicker
                                            sx={{ width: '100%' }}
                                            disableFuture
                                            showDaysOutsideCurrentMonth
                                            views={['year', 'month', 'day']}
                                            format="DD MMMM YYYY"
                                            label={'Ամսաթիվ'}
                                            value={dayjs(
                                                personalInfoQuery.data
                                                    ?.birthDate,
                                            )}
                                            inputRef={birthDateInputRef}
                                            onChange={(
                                                newValue: any,
                                                context: FieldChangeHandlerContext<any>,
                                            ) => {
                                                newValue &&
                                                context.validationError !==
                                                    'invalidDate'
                                                    ? setStartDateError(false)
                                                    : setStartDateError(true);
                                                setPersonalInfo(
                                                    (prevState: any) => {
                                                        return {
                                                            ...prevState,
                                                            birthDate:
                                                                dayjs(
                                                                    newValue,
                                                                ).format(
                                                                    'YYYY-MM-DD',
                                                                ),
                                                        };
                                                    },
                                                );
                                            }}
                                            slots={{
                                                openPickerIcon:
                                                    CalendarMonthIcon,
                                            }}
                                            slotProps={{
                                                textField: {
                                                    InputLabelProps: {
                                                        margin: 'dense',
                                                    },
                                                    error: startDateError,
                                                },
                                                actionBar: ({
                                                    wrapperVariant,
                                                }) => ({
                                                    actions:
                                                        wrapperVariant ===
                                                        'mobile'
                                                            ? [
                                                                  'cancel',
                                                                  'accept',
                                                              ]
                                                            : [],
                                                }),
                                            }}
                                            dayOfWeekFormatter={(
                                                _day: string,
                                                date: Dayjs,
                                            ) =>
                                                dayjs(date)
                                                    .format('dd')
                                                    .toUpperCase()
                                            }
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} md={6} lg={6} />
                            </Grid>
                            <Box
                                display={'flex'}
                                justifyContent={'end'}
                                mt={pxToRem(32)}
                            >
                                <Button
                                    onClick={() => handleFilterDataPerson()}
                                    variant={'contained'}
                                >
                                    Պահպանել
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </>
    );
};
export default PersonalInformation;
