import colors from 'assets/theme/base/colors';
import borders from 'assets/theme/base/borders';
import typography from 'assets/theme/base/typography';
import pxToRem from 'assets/theme/function/px-to-rem';

const { text } = colors;
const { borderRadius } = borders;
const { size } = typography;

const menuItem = {
    styleOverrides: {
        root: {
            minWidth: pxToRem(160),
            minHeight: 'unset',
            padding: `${pxToRem(4.8)} ${pxToRem(16)}`,
            borderRadius: borderRadius.md,
            fontSize: size.sm,
            color: text.primary,
            transition: 'background-color 300ms ease, color 300ms ease',

            '&:hover, &:focus, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus':
                {
                    //backgroundColor: light.main,
                    //color: dark.main,
                },
        },
    },
};

export default menuItem;
