import { apiSlice } from '../api/api-slice';
import serializeObjectToURI from 'utils/serializer';
import {
    CasesFullDataRequest,
    CasesFullDataResponse,
} from 'types/cases/case-full-data';

export const caseFullDataApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        caseFullData: builder.query<
            CasesFullDataResponse,
            CasesFullDataRequest
        >({
            query: (casesFullDataRequest: CasesFullDataRequest) => ({
                url: serializeObjectToURI(
                    casesFullDataRequest,
                    'public/full-data?',
                ),
                method: 'GET',
            }),
        }),
    }),
});

export const { useLazyCaseFullDataQuery } = caseFullDataApi;
