import colors from 'assets/theme/base/colors';

const textField = {
    styleOverrides: {
        root: {
            backgroundColor: colors.white.main,
            //borderRadius: 'none',
        },
        label: {
            textAlign: 'center',
            color: colors.text.secondary,
        },
    },
};

export default textField;
