import * as React from 'react';
import {
    Box,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Radio,
    RadioGroup,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import pxToRem from 'assets/theme/function/px-to-rem';
import { ReactComponent as InfoIcon } from 'assets/images/inputIcon/infoIcon.svg';
import CaseTypeFilterAction from 'pages/case-search/case-type-filter/case-type-filter-action';
import AdvancedSearch from 'pages/case-search/case-type-filter/advanced-search/advanced-search';
import AdvancedSearchShort from 'pages/case-search/case-type-filter/advanced-search/advanced-search-short';
import { CasesTypeRequest } from 'types/cases/cases';
import Decisions, { decisionSearchTypes } from 'component/decisions';
import { useMemo } from 'react';

interface Props {
    caseType: string;
    setSearchingFields: React.Dispatch<
        React.SetStateAction<CasesTypeRequest | undefined>
    >;
    searchingFields?: CasesTypeRequest;
}
const decisions: React.RefObject<HTMLInputElement>[] = [];
const decisionSearchType: React.RefObject<HTMLInputElement>[] = [];

const CivilCase: React.FC<Props> = (props: Props) => {
    const { caseType, setSearchingFields, searchingFields } = props;

    const [isPerson, setIsPerson] = React.useState<boolean>(true);
    const [isRespondent, setIsRespondent] = React.useState<boolean>(true);
    const [addedComponents, setAddedComponents] = React.useState<{
        [key: string]: JSX.Element;
    }>({});
    const [keyCounter, setKeyCounter] = React.useState<number>(0);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickDecisions = async () => {
        const newDecisions: string[] = [];
        const newDecisionSearchType: string[] = [];
        if (decisions.length >= 1) {
            decisions.forEach((item) => {
                if (item?.current?.value) {
                    newDecisions.push(item.current.value);
                }
            });
        }
        if (decisionSearchType.length >= 1) {
            decisionSearchType.forEach((item) => {
                const value = findDecisionTypeValue(item?.current?.value);
                if (value) {
                    newDecisionSearchType.push(value.value);
                }
            });
        }

        await setSearchingFields((prevState) => ({
            ...prevState,
            decisions: newDecisions,
            decisionSearchType: newDecisionSearchType,
        }));
    };

    const findDecisionTypeValue = (title?: string) => {
        return decisionSearchTypes.find((element) => element.label === title);
    };

    const getClaimantTitle = useMemo(() => {
        let claimantTitle = 'ՀԱՅՑՈՐ';

        if (caseType === 'pay_order') {
            claimantTitle = 'ԴԻՄՈՂ';
        } else if (caseType === 'bankruptcy') {
            claimantTitle = 'ԴԻՄՈՒՄԱՏՈՒ';
        }

        return claimantTitle;
    }, [caseType]);

    const getRespondentTitle = useMemo(() => {
        let claimantTitle = 'ՊԱՏԱՍԽԱՆՈՂ';

        if (caseType === 'bankruptcy') {
            claimantTitle = 'ՊԱՐՏԱՊԱՆ';
        }

        return claimantTitle;
    }, [caseType]);

    const personHandleChange = () => {
        setIsPerson((prevIsPerson) => !prevIsPerson);
    };

    const organizationHandleChange = () => {
        setIsRespondent((prevIsRespondent) => !prevIsRespondent);
    };

    const resetComponent = (key?: string) => {
        if (key === undefined) {
            return;
        }
        setAddedComponents((prevComponents) => {
            const updatedComponents = { ...prevComponents };
            if (updatedComponents[key]) {
                delete updatedComponents[key];
            }
            return updatedComponents;
        });
    };

    const addComponent = () => {
        const newKey = `decision-${keyCounter}`;
        setAddedComponents((prevComponents) => ({
            ...prevComponents,
            [newKey]: (
                <Decisions
                    key={newKey}
                    decisionKey={newKey}
                    addComponent={addComponent}
                    resetComponent={() => resetComponent(newKey)}
                    addedComponentsPlus={false}
                    decisions={decisions}
                    decisionSearchType={decisionSearchType}
                    handleClickDecisions={handleClickDecisions}
                />
            ),
        }));
        setKeyCounter((prevCounter) => prevCounter + 1);
    };

    return (
        <Box>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={4} mt={pxToRem(62)}>
                    <TextField
                        fullWidth
                        label={'Գործի համար'}
                        name={'caseNumber'}
                        value={searchingFields?.caseNumber || ''}
                        onChange={(e) => {
                            const { name, value } = e.target;
                            if (setSearchingFields) {
                                setSearchingFields((prevState: any) => ({
                                    ...prevState,
                                    [name]: value,
                                }));
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onMouseDown={(e) => e.preventDefault()}
                                        edge="end"
                                    >
                                        <InfoIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    {caseType !== 'bankruptcy' && (
                        <TextField
                            fullWidth
                            label={'Պահանջ'}
                            name={'claim'}
                            value={searchingFields?.claim || ''}
                            onChange={(e) => {
                                const { name, value } = e.target;
                                if (setSearchingFields) {
                                    setSearchingFields((prevState: any) => ({
                                        ...prevState,
                                        [name]: value,
                                    }));
                                }
                            }}
                            sx={{ mt: isMobile ? pxToRem(8) : pxToRem(16) }}
                        />
                    )}
                </Grid>
                {isTablet ? <Grid item sm={6} /> : ''}

                <Grid item xs={12} sm={6} md={4}>
                    <Typography variant={isMobile ? 'h6' : 'h5'}>
                        {getClaimantTitle}
                    </Typography>

                    <FormControl>
                        <RadioGroup value={isPerson} row>
                            <FormControlLabel
                                control={
                                    <Radio
                                        value={true}
                                        size={'small'}
                                        onChange={personHandleChange}
                                    />
                                }
                                label=" Ֆիզ.անձ"
                            />
                            <FormControlLabel
                                control={
                                    <Radio
                                        size={'small'}
                                        onChange={personHandleChange}
                                        value={false}
                                    />
                                }
                                label="Կազմ/Պետ հաստատ."
                            />
                        </RadioGroup>
                    </FormControl>

                    {isPerson ? (
                        <>
                            <TextField
                                fullWidth
                                label={'Անուն'}
                                name={'claimantApplicantFirstName'}
                                value={
                                    searchingFields?.claimantApplicantFirstName ||
                                    ''
                                }
                                onChange={(e) => {
                                    const { name, value } = e.target;
                                    if (setSearchingFields) {
                                        setSearchingFields(
                                            (prevState: any) => ({
                                                ...prevState,
                                                [name]: value,
                                            }),
                                        );
                                    }
                                }}
                            />
                            <TextField
                                fullWidth
                                label={'Ազգանուն'}
                                name={'claimantApplicantLastName'}
                                value={
                                    searchingFields?.claimantApplicantLastName ||
                                    ''
                                }
                                onChange={(e) => {
                                    const { name, value } = e.target;
                                    if (setSearchingFields) {
                                        setSearchingFields(
                                            (prevState: any) => ({
                                                ...prevState,
                                                [name]: value,
                                            }),
                                        );
                                    }
                                }}
                                sx={{ mt: isMobile ? pxToRem(8) : pxToRem(16) }}
                            />
                        </>
                    ) : (
                        <TextField
                            fullWidth
                            label={'Անվանում'}
                            name={'claimantApplicantOrganizationName'}
                            value={
                                searchingFields?.claimantApplicantOrganizationName ||
                                ''
                            }
                            onChange={(e) => {
                                const { name, value } = e.target;
                                if (setSearchingFields) {
                                    setSearchingFields((prevState: any) => ({
                                        ...prevState,
                                        [name]: value,
                                    }));
                                }
                            }}
                            sx={{ mt: isMobile ? pxToRem(8) : pxToRem(0) }}
                        />
                    )}
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <Typography variant={isMobile ? 'h6' : 'h5'}>
                        {getRespondentTitle}
                    </Typography>

                    <FormControl>
                        <RadioGroup value={isRespondent} row>
                            <FormControlLabel
                                control={
                                    <Radio
                                        size={'small'}
                                        onChange={organizationHandleChange}
                                        value={true}
                                    />
                                }
                                label=" Ֆիզ.անձ"
                            />
                            <FormControlLabel
                                control={
                                    <Radio
                                        size={'small'}
                                        onChange={organizationHandleChange}
                                        value={false}
                                    />
                                }
                                label="Կազմ/Պետ հաստատ."
                            />
                        </RadioGroup>
                    </FormControl>

                    {isRespondent ? (
                        <>
                            <TextField
                                fullWidth
                                label={'Անուն'}
                                name={'respondentFirstName'}
                                value={
                                    searchingFields?.respondentFirstName || ''
                                }
                                onChange={(e) => {
                                    const { name, value } = e.target;
                                    if (setSearchingFields) {
                                        setSearchingFields(
                                            (prevState: any) => ({
                                                ...prevState,
                                                [name]: value,
                                            }),
                                        );
                                    }
                                }}
                            />
                            <TextField
                                fullWidth
                                label={'Ազգանուն'}
                                name={'respondentLastName'}
                                value={
                                    searchingFields?.respondentLastName || ''
                                }
                                onChange={(e) => {
                                    const { name, value } = e.target;
                                    if (setSearchingFields) {
                                        setSearchingFields(
                                            (prevState: any) => ({
                                                ...prevState,
                                                [name]: value,
                                            }),
                                        );
                                    }
                                }}
                                sx={{ mt: isMobile ? pxToRem(8) : pxToRem(16) }}
                            />
                        </>
                    ) : (
                        <TextField
                            fullWidth
                            label={'Անվանում'}
                            name={'respondentOrganizationName'}
                            value={
                                searchingFields?.respondentOrganizationName ||
                                ''
                            }
                            onChange={(e) => {
                                const { name, value } = e.target;
                                if (setSearchingFields) {
                                    setSearchingFields((prevState: any) => ({
                                        ...prevState,
                                        [name]: value,
                                    }));
                                }
                            }}
                            sx={{ mt: isMobile ? pxToRem(8) : pxToRem(0) }}
                        />
                    )}
                </Grid>

                {caseType !== 'pay_order' && (
                    <>
                        <Decisions
                            key={'key'}
                            addComponent={addComponent}
                            addedComponentsPlus={true}
                            resetComponent={resetComponent}
                            decisions={decisions}
                            decisionSearchType={decisionSearchType}
                            handleClickDecisions={handleClickDecisions}
                        />
                        {Object.values(addedComponents).map((decision) => (
                            <Box
                                display={'flex'}
                                width={'100%'}
                                key={decision.key}
                            >
                                {decision}
                            </Box>
                        ))}
                    </>
                )}
            </Grid>

            {caseType === 'pay_order' ? (
                <AdvancedSearchShort
                    setSearchingFields={setSearchingFields}
                    searchingFields={searchingFields}
                />
            ) : (
                <AdvancedSearch
                    setSearchingFields={setSearchingFields}
                    searchingFields={searchingFields}
                />
            )}

            <CaseTypeFilterAction />
        </Box>
    );
};
export default CivilCase;
