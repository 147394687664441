import React, { useEffect } from 'react';
import { Box, Button } from '@mui/material';
import pxToRem from 'assets/theme/function/px-to-rem';
import {
    resetCaseStart,
    startCaseSearch,
} from 'store/slices/cases/case-search-slice';
import { useAppDispatch } from 'hooks/redux';
import { useKeyPress } from '@react-typed-hooks/use-key-press';

const CaseTypeFilterAction = () => {
    const dispatch = useAppDispatch();

    const resetCaseSearch = async (): Promise<void> => {
        dispatch(resetCaseStart('press-search'));
    };

    const handleCaseSearch = async (): Promise<void> => {
        dispatch(startCaseSearch('press-search'));
    };

    const isPressed = useKeyPress({ targetKey: 'Enter' });

    useEffect(() => {
        if (isPressed) {
            handleCaseSearch().then();
        }
    }, [isPressed]);

    return (
        <Box
            onKeyUp={handleCaseSearch}
            display={'flex'}
            justifyContent={'end'}
            alignItems={'center'}
        >
            <Button
                sx={{ mr: pxToRem(16) }}
                size={'medium'}
                variant={'contained'}
                onClick={handleCaseSearch}
            >
                Որոնել
            </Button>
            <Button onClick={resetCaseSearch} variant={'outlined'}>
                Մաքրել
            </Button>
        </Box>
    );
};

export default CaseTypeFilterAction;
