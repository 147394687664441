import { apiSlice } from '../api/api-slice';
import {
    EmailConfirmRequest,
    ResetPassword,
    SendResetPassword,
} from 'types/user/user';
import { ChangPasswordRequest } from 'types/chang-password/chang-password';

export const userResetAuthApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        resetPassword: builder.mutation({
            query: (resetPassword: ResetPassword) => ({
                url: '/auth/reset-password',
                method: 'PATCH',
                body: { ...resetPassword },
                responseHandler: (response) => response.text(),
            }),
        }),
        emailConfirm: builder.mutation({
            query: (emailConfirmRequest: EmailConfirmRequest) => ({
                url: '/auth/email-confirm',
                method: 'POST',
                body: { ...emailConfirmRequest },
                responseHandler: (response) => {
                    return response.text();
                },
            }),
        }),
        sendResetPassword: builder.mutation({
            query: (sendResetPassword: SendResetPassword) => ({
                url: '/auth/send-reset-password',
                method: 'POST',
                body: { ...sendResetPassword },
                responseHandler: (response) => response.text(),
            }),
        }),
        changPassword: builder.mutation({
            query: (changPasswordRequest: ChangPasswordRequest) => ({
                url: '/app/change-password?',
                method: 'PATCH',
                body: { ...changPasswordRequest },
                responseHandler: (response) => response.text(),
            }),
        }),
    }),
});
export const {
    useResetPasswordMutation,
    useSendResetPasswordMutation,
    useEmailConfirmMutation,
    useChangPasswordMutation,
} = userResetAuthApi;
