import pxToRem from 'assets/theme/function/px-to-rem';

const stack = {
    styleOverrides: {
        root: {
            paddingTop: pxToRem(0),
        },
    },
};

export default stack;
