import borders from '../base/borders';
import pxToRem from '../function/px-to-rem';

const { borderRadius } = borders;

const sideNav = {
    styleOverrides: {
        root: {
            width: pxToRem(250),
            //whiteSpace: 'nowrap',
            border: 'none',
        },

        paper: {
            width: pxToRem(250),
            backgroundColor: 'transparent',
            height: `calc(100vh - ${pxToRem(32)})`,
            margin: pxToRem(16),
            borderRadius: borderRadius.xl,
            border: 'none',
        },

        paperAnchorDockedLeft: {
            borderRight: 'none',
        },
    },
};

export default sideNav;
