import typography from 'assets/theme/base/typography';
import borders from 'assets/theme/base/borders';
import pxToRem from 'assets/theme/function/px-to-rem';

const { size } = typography;
const { borderWidth, borderColor } = borders;

const dialogContent = {
    styleOverrides: {
        root: {
            fontSize: size.md,
            paddingLeft: pxToRem(50),
            paddingRight: pxToRem(50),
            //color: white.main,
        },

        dividers: {
            borderTop: `${borderWidth[1]} solid ${borderColor}`,
            borderBottom: `${borderWidth[1]} solid ${borderColor}`,
        },
    },
};

export default dialogContent;
