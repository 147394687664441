const colors = {
    background: {
        default: '#FFFFFF',
    },

    text: {
        primary: 'rgba(32, 36, 61, 1)',
        secondary: '#59608A',
        placeholder: '#8C96C4',
    },

    footer: {
        background: '#20243D',
    },

    white: {
        main: '#FFFFFF',
    },

    black: {
        main: '#000000',
    },

    primary: {
        main: '#EEA43B',
    },

    secondary: {
        main: '#3263C2',
        light: '#C46000',
        ligthHover: '#C8E6C9',
    },

    info: {
        main: '#DDE2F5',
        secondary: '#F1F4FF',
        focus: 'rgba(32, 36, 61, 0.20)',
    },

    success: {
        main: '#4CAF50',
        secondary: '#3263C2',
    },

    warning: {
        main: '#BEC6EB',
        secondary: '#FFECB3',
    },

    error: {
        main: '#EF5350',
        secondary: '#E53935',
        light: '#EEEEEE',
    },
    inputBorderColor: '#BEC6EB',

    grey: {
        100: '#EEEEEE',
        200: '#f2f2f2',
        300: '#E9E9E9',
        400: '#ced4da',
        500: '#adb5bd',
        600: '#6c757d',
        700: '#495057',
        800: '#343a40',
        900: '#212529',
    },
};

export default colors;
