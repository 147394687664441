import borders from 'assets/theme/base/borders';
import colors from 'assets/theme/base/colors';
import pxToRem from 'assets/theme/function/px-to-rem';
const { borderWidth } = borders;
const { text } = colors;

const tableCell = {
    defaultProps: {
        //align: 'center' as const,
    },
    styleOverrides: {
        root: {
            fontSize: pxToRem(14),
            fontWeight: 400,
            lineHeight: pxToRem(19.6),
            //border: 'none',
            //borderColor: colors.info.focus,
            padding: `${pxToRem(3)} ${pxToRem(7)}`,
        },

        head: {
            color: text.primary,
            fontWeight: 700,
            borderRight: `${borderWidth[1]} solid ${colors.info.focus}`,
            backgroundColor: colors.info.main,
            ' &&.MuiTableCell-root': {
                textAlign: 'center',
            },
        },

        body: {
            color: text.primary,
            '.MuiTableCell-body': {
                textAlign: 'center',
            },
        },

        /*small: {
            fontSize: pxToRem(10.4),
            textAlign: 'center',
        },

        medium: {
            backgroundColor: '#211',
        },*/
    },
};

export default tableCell;
