import * as React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import pxToRem from 'assets/theme/function/px-to-rem';
import { ReactComponent as Round } from 'assets/images/dialog/round.svg';

interface Props {
    openEmailSuccessfullyResetDialog: boolean;
    setOpenEmailSuccessfullyResetDialog: React.Dispatch<
        React.SetStateAction<boolean>
    >;
}

const RegisterResetSuccessfully: React.FC<Props> = (props: Props) => {
    const {
        openEmailSuccessfullyResetDialog,
        setOpenEmailSuccessfullyResetDialog,
    } = props;
    return (
        <>
            <Dialog
                open={openEmailSuccessfullyResetDialog}
                onClose={() => {
                    setOpenEmailSuccessfullyResetDialog(false);
                }}
                PaperProps={{
                    sx: {
                        width: pxToRem(540),
                    },
                }}
            >
                <DialogTitle textAlign={'center'} variant={'h3'}>
                    Դուք հաջողությամբ գրանցվել էք
                </DialogTitle>
                <DialogContent
                    sx={{
                        textAlign: 'center',
                        my: pxToRem(20),
                    }}
                >
                    <Round />
                </DialogContent>
            </Dialog>
        </>
    );
};
export default RegisterResetSuccessfully;
