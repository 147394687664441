import colors from 'assets/theme/base/colors';

const tableFooter = {
    styleOverrides: {
        root: {
            backgroundColor: colors.secondary.main,
            '.MuiTableCell-root': {
                color: colors.text.primary,
                fontWeight: 700,
                textAlign: 'center',
            },
        },
    },
};

export default tableFooter;
