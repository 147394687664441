import borders from 'assets/theme/base/borders';

const { borderRadius } = borders;

const dialog = {
    styleOverrides: {
        paper: {
            borderRadius: borderRadius.lg,
        },

        paperFullScreen: {
            borderRadius: 0,
        },
    },
};

export default dialog;
