import * as React from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from '@mui/material';
import pxToRem from 'assets/theme/function/px-to-rem';
import { ResetPassword } from 'types/user/user';
import { useEffect, useState } from 'react';
import { openSnackbar } from 'store/slices/snackbar/snackbar-slice';
import { useAppDispatch } from 'hooks/redux';
import { ReactComponent as Eye } from 'assets/images/group.svg';
import { ReactComponent as NounEye } from 'assets/images/noun.svg';
import { useResetPasswordMutation } from 'store/slices/user/user-reset-password';
import colors from 'assets/theme/base/colors';
import { useNavigate, useParams } from 'react-router-dom';
interface Props {
    openSecurityCodeDialog: boolean;
    setOpenSecurityCodeDialog: React.Dispatch<React.SetStateAction<boolean>>;
    setOpenSuccessfullyResetDialog: React.Dispatch<
        React.SetStateAction<boolean>
    >;
}
const SecurityCode: React.FC<Props> = (props: Props) => {
    const {
        openSecurityCodeDialog,
        setOpenSecurityCodeDialog,
        setOpenSuccessfullyResetDialog,
    } = props;
    const [showPass, setShowPass] = useState<boolean>(false);
    const [showPassConfirm, setShowPassConfirm] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [user, setUser] = useState<ResetPassword>({
        passwordConfirm: '',
        password: '',
        token: '',
    });
    const { token } = useParams<{ token: string }>();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    let isValid = true;
    let errorText = '';
    const [resetPassword, { isError, error }] = useResetPasswordMutation();

    const validatePlantingMaterialInfo = (): boolean => {
        if (
            user.passwordConfirm == '' ||
            user.passwordConfirm == 'Invalid Date'
        ) {
            isValid = false;
            errorText = 'Դուք ունեք դատարկ դաշտեր';
        }
        if (user.password == '' || user.password == 'Invalid Date') {
            isValid = false;
            errorText = 'Դուք ունեք դատարկ դաշտեր';
        }

        if (!isValid) {
            dispatch(
                openSnackbar({
                    message: errorText,
                    severity: 'error',
                    open: true,
                    position: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                }),
            );
        }

        return isValid;
    };
    const handleSecretingReset = async () => {
        if (token) {
            user.token = token;
        }
        if (validatePlantingMaterialInfo()) {
            await resetPassword(user);
        }
        navigate('/');
        setOpenSuccessfullyResetDialog(true);
    };

    useEffect(() => {
        if (isError) {
            setOpenSecurityCodeDialog(true);
            setOpenSuccessfullyResetDialog(false);
        }
    }, [isError]);

    useEffect(() => {
        if (error && 'data' in error) {
            setErrorMessage(error?.data.message);
        }
    }, [isError]);

    return (
        <>
            <Dialog
                open={openSecurityCodeDialog}
                PaperProps={{
                    sx: {
                        width: pxToRem(540),
                    },
                }}
            >
                <Typography textAlign={'center'} color={colors.error.main}>
                    {errorMessage}
                </Typography>
                <Box onSubmit={handleSecretingReset}>
                    <DialogTitle textAlign={'center'} variant={'h3'}>
                        Փոխել Գաղտնաբառը
                    </DialogTitle>
                    <DialogContent>
                        <Box paddingTop={pxToRem(20)}>
                            <TextField
                                sx={{ mt: pxToRem(10) }}
                                fullWidth
                                label="Նոր Գաղտնաբառ"
                                name="password"
                                type={showPass ? 'text' : 'password'}
                                aria-describedby="passwordTextHelper"
                                InputProps={{
                                    'aria-label': 'password',
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() =>
                                                    setShowPass(
                                                        (current) => !current,
                                                    )
                                                }
                                                onMouseDown={(e) =>
                                                    e.preventDefault()
                                                }
                                                edge="end"
                                            >
                                                {showPass ? (
                                                    <Eye />
                                                ) : (
                                                    <NounEye />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(e) => {
                                    const { name, value } = e.target;
                                    setUser((prevState: ResetPassword) => ({
                                        ...prevState,
                                        [name]: value,
                                    }));
                                }}
                            />
                            <TextField
                                sx={{ mt: pxToRem(10) }}
                                fullWidth
                                label="Հաստատել Գաղտնաբառ"
                                name="passwordConfirm"
                                type={
                                    showPassConfirm ? 'text' : 'passwordConfirm'
                                }
                                aria-describedby="passwordTextHelper"
                                InputProps={{
                                    'aria-label': 'passwordConfirm',
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() =>
                                                    setShowPassConfirm(
                                                        (current) => !current,
                                                    )
                                                }
                                                onMouseDown={(e) =>
                                                    e.preventDefault()
                                                }
                                                edge="end"
                                            >
                                                {showPassConfirm ? (
                                                    <Eye />
                                                ) : (
                                                    <NounEye />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(e) => {
                                    const { name, value } = e.target;
                                    setUser((prevState: ResetPassword) => ({
                                        ...prevState,
                                        [name]: value,
                                    }));
                                }}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Box display={'flex'} justifyContent={'end'}>
                            <Button
                                className="logout-confirm-button"
                                variant="contained"
                                color="primary"
                                onClick={handleSecretingReset}
                            >
                                Հաստատել
                            </Button>
                        </Box>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
};
export default SecurityCode;
