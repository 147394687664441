import pxToRem from 'assets/theme/function/px-to-rem';

const dateCalendar = {
    styleOverrides: {
        root: {
            width: pxToRem(300),
            height: pxToRem(300),

            '& .MuiYearCalendar-root, & .MuiMonthCalendar-root': {
                width: pxToRem(300),
            },
        },
    },
};
export default dateCalendar;
