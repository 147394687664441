import { createTheme } from '@mui/material';

import colors from 'assets/theme/base/colors';
import typography from 'assets/theme/base/typography';
import breakpoints from 'assets/theme/base/breakpoints';
import globals from 'assets/theme/base/globals';
import sideNav from 'assets/theme/components/side-nav';
import list from 'assets/theme/components/list';
import listItem from 'assets/theme/components/list/listItem';
import listItemText from 'assets/theme/components/list/listItem-text';
import button from 'assets/theme/components/button';
import iconButton from 'assets/theme/components/icon-button';
import input from 'assets/theme/components/form/input';
import inputLabel from 'assets/theme/components/form/input-label';
import inputOutlined from 'assets/theme/components/form/input-outlined';
import textField from 'assets/theme/components/form/text-field';
import menu from 'assets/theme/components/menu';
import menuItem from 'assets/theme/components/menu/menu-item';
import switchButton from 'assets/theme/components/form/switch-button';
import select from 'assets/theme/components/form/select';
import formControlLabel from 'assets/theme/components/form/form-control-label';
import formLabel from 'assets/theme/components/form/form-label';
import checkbox from 'assets/theme/components/form/checkbox';
import autoComplete from 'assets/theme/components/form/auto-complete';
import icon from 'assets/theme/components/icon';
import svgIcon from 'assets/theme/components/svg-icon';
import link from 'assets/theme/components/link';
import dialog from 'assets/theme/components/dialog';
import dialogTitle from 'assets/theme/components/dialog/dialog-title';
import dialogContent from 'assets/theme/components/dialog/dialog-content';
import dialogContentText from 'assets/theme/components/dialog/dialog-content-text';
import dialogActions from 'assets/theme/components/dialog/dialog-action';
import appBar from 'assets/theme/components/app-bar';
import tableCell from 'assets/theme/components/table/table-cell';
import listItemButton from 'assets/theme/components/list/listItemButton';

import type {} from '@mui/x-date-pickers/themeAugmentation';
import dateCalendar from 'assets/theme/components/date-picker';
import pickersPopper from 'assets/theme/components/date-picker/pickers-popper';
import calendarHeader from 'assets/theme/components/date-picker/calendar-header';
import dayCalendar from 'assets/theme/components/date-picker/day-calendar';
import pickersDay from 'assets/theme/components/date-picker/pickers-day';
import pickersYear from 'assets/theme/components/date-picker/pickers-year';
import pickersMonth from 'assets/theme/components/date-picker/pickers-month';
import tableContainer from 'assets/theme/components/table/table-container';
import tableFooter from 'assets/theme/components/table/table-footer';
import tableHead from 'assets/theme/components/table/table-head';
import tableSortLable from 'assets/theme/components/table/table-sort-lable';
import tablePagination from 'assets/theme/components/table/table-pagination';
import radio from 'assets/theme/components/form/radio';
import tab from 'assets/theme/components/tab/tab';
import tabs from 'assets/theme/components/tab/tabs';
import divider from 'assets/theme/components/divider';
import stack from 'assets/theme/components/stack/stack';

export default createTheme({
    breakpoints: { ...breakpoints },
    palette: { ...colors },
    typography: { ...typography },
    //boxShadows: { ...boxShadows },
    //borders: { ...borders },

    components: {
        MuiCssBaseline: {
            styleOverrides: {
                ...globals,
            },
        },
        MuiDrawer: { ...sideNav },
        MuiAppBar: { ...appBar },
        MuiList: { ...list },
        MuiListItem: { ...listItem },
        MuiListItemButton: { ...listItemButton },
        MuiListItemText: { ...listItemText },
        MuiButton: { ...button },
        MuiIconButton: { ...iconButton },
        MuiInput: { ...input },
        MuiInputLabel: { ...inputLabel },
        MuiOutlinedInput: { ...inputOutlined },
        MuiTextField: { ...textField },
        MuiMenu: { ...menu },
        MuiMenuItem: { ...menuItem },
        MuiSwitch: { ...switchButton },
        MuiSelect: { ...select },
        MuiFormControlLabel: { ...formControlLabel },
        MuiRadio: { ...radio },
        MuiFormLabel: { ...formLabel },
        MuiCheckbox: { ...checkbox },
        MuiAutocomplete: { ...autoComplete },
        MuiIcon: { ...icon },
        MuiSvgIcon: { ...svgIcon },
        MuiLink: { ...link },
        MuiDialog: { ...dialog },
        MuiDialogTitle: { ...dialogTitle },
        MuiDialogContent: { ...dialogContent },
        MuiDialogContentText: { ...dialogContentText },
        MuiDialogActions: { ...dialogActions },
        MuiTableContainer: { ...tableContainer },
        MuiTableHead: { ...tableHead },
        MuiTableFooter: { ...tableFooter },
        MuiTableCell: { ...tableCell },
        MuiTableSortLabel: { ...tableSortLable },
        MuiTablePagination: { ...tablePagination },
        MuiPickersPopper: { ...pickersPopper },
        MuiDateCalendar: { ...dateCalendar },
        MuiPickersCalendarHeader: { ...calendarHeader },
        MuiDayCalendar: { ...dayCalendar },
        MuiPickersYear: { ...pickersYear },
        MuiPickersMonth: { ...pickersMonth },
        MuiPickersDay: { ...pickersDay },
        MuiTab: { ...tab },
        MuiTabs: { ...tabs },
        MuiDivider: { ...divider },
        MuiStack: { ...stack },
    },
});
