import colors from 'assets/theme/base/colors';
import typography from 'assets/theme/base/typography';

const { text } = colors;
const { size, fontWeightRegular } = typography;

const formControlLabel = {
    styleOverrides: {
        label: {
            fontSize: size.sm,
            fontWeight: fontWeightRegular,
            color: text.primary,
            //lineHeight: 1,
            //transform: `translateY(${pxToRem(100)})`,

            '&.Mui-disabled': {
                //color: dark.main,
            },
        },
    },
};

export default formControlLabel;
