import colors from 'assets/theme/base/colors';
import borders from 'assets/theme/base/borders';

const { borderWidth } = borders;

const divider = {
    styleOverrides: {
        root: {
            '&:before': {
                borderTop: `${borderWidth[1]} solid ${colors.info.focus}`,
            },
            '&:after': {
                borderTop: `${borderWidth[1]} solid ${colors.info.focus}`,
            },
        },
    },
};

export default divider;
