import borders from 'assets/theme/base/borders';
import typography from 'assets/theme/base/typography';
import colors from 'assets/theme/base/colors';

const { fontWeightRegular, fontWeightBold, size } = typography;
const { borderRadius } = borders;

const pickersYear = {
    styleOverrides: {
        yearButton: {
            color: colors.text.primary,
            fontSize: size.sm,
            fontWeight: fontWeightRegular,

            '&:hover': {
                backgroundColor: colors.info.main,
                borderRadius: borderRadius.lg,
            },

            '&.Mui-selected, &:focus.Mui-selected, &.Mui-selected:hover': {
                color: colors.text.primary,
                backgroundColor: colors.info.main,
                borderRadius: borderRadius.lg,
                fontWeight: fontWeightBold,
            },
        },
    },
};

export default pickersYear;
