import colors from 'assets/theme/base/colors';
import typography from 'assets/theme/base/typography';
import borders from 'assets/theme/base/borders';
import pxToRem from 'assets/theme/function/px-to-rem';

const { primary, text, secondary, inputBorderColor } = colors;
const { size } = typography;
const { borderWidth, borderRadius } = borders;

const outlined = {
    base: {
        color: text.primary,
        border: `${borderWidth[1]} solid ${inputBorderColor}`,

        '&:hover': {
            opacity: 0.75,
        },
    },

    small: {
        height: pxToRem(24),
        // padding: `${pxToRem(6)} ${pxToRem(15)}`,
    },
    medium: {
        height: pxToRem(44),
        backgroundColor: 'transparent',
    },

    large: {
        height: pxToRem(50),
        //padding: `${pxToRem(14)} ${pxToRem(30)}`,
        fontSize: size.md,
        borderRadius: borderRadius.xl,

        '& .material-icon, .material-icons-round, svg': {
            fontSize: size.md,
        },
    },

    primary: {
        backgroundColor: colors.white.main,
        color: colors.text.primary,
        borderColor: colors.inputBorderColor,

        '&:hover': {
            backgroundColor: colors.white.main,
            borderColor: colors.inputBorderColor,
            color: colors.text.primary,
        },
    },

    secondary: {
        backgroundColor: secondary.ligthHover,
        borderColor: 'none',
        color: primary.main,

        '&:hover': {
            backgroundColor: secondary.ligthHover,
        },
    },
};

export default outlined;
