import * as React from 'react';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from '@mui/material';
import pxToRem from 'assets/theme/function/px-to-rem';
import colors from 'assets/theme/base/colors';
import { UserRequest } from 'types/user/user';
import { ReactComponent as Eye } from 'assets/images/group.svg';
import { ReactComponent as NounEye } from 'assets/images/noun.svg';
import { useSignInMutation } from 'store/slices/user/user-auth-api';
import { useEffect, useState } from 'react';
import { openSnackbar } from 'store/slices/snackbar/snackbar-slice';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks/redux';
interface Props {
    openDialog: boolean;
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
    setOpenRegisterDialog: React.Dispatch<React.SetStateAction<boolean>>;
    setOpenSecretingDialog: React.Dispatch<React.SetStateAction<boolean>>;
}
const Login: React.FC<Props> = (props: Props) => {
    const {
        openDialog,
        setOpenDialog,
        setOpenRegisterDialog,
        setOpenSecretingDialog,
    } = props;
    const [showPass, setShowPass] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [user, setUser] = useState<UserRequest>({
        email: '',
        password: '',
    });
    const dispatch = useAppDispatch();

    let isValid = true;
    let errorText = '';
    const [signInApi, { isSuccess, error, isError }] = useSignInMutation();

    const validatePlantingMaterialInfo = (): boolean => {
        if (user.email == '' || user.email == 'Invalid Date') {
            isValid = false;
            errorText = 'Դուք ունեք դատարկ դաշտեր';
        }
        if (user.password == '' || user.password == 'Invalid Date') {
            isValid = false;
            errorText = 'Դուք ունեք դատարկ դաշտեր';
        }

        if (!isValid) {
            dispatch(
                openSnackbar({
                    message: errorText,
                    severity: 'error',
                    open: true,
                    position: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                }),
            );
        }

        return isValid;
    };

    const handleSignIn = async () => {
        if (validatePlantingMaterialInfo()) {
            await signInApi(user);
        }
    };
    const handelRegister = async () => {
        setOpenDialog(false);
        setOpenRegisterDialog(true);
    };
    const navigate = useNavigate();

    useEffect(() => {
        if (isSuccess) {
            setOpenDialog(false);
            navigate('/');
        }
    }, [isSuccess]);

    useEffect(() => {
        if (error && 'data' in error) {
            setErrorMessage(error?.data.message);
        }
    }, [isError]);

    return (
        <>
            <Dialog
                open={openDialog}
                onClose={() => {
                    setOpenDialog(false);
                }}
                PaperProps={{
                    sx: {
                        width: pxToRem(540),
                    },
                }}
            >
                <Typography textAlign={'center'} color={colors.error.main}>
                    {errorMessage}
                </Typography>
                <Box>
                    <DialogTitle textAlign={'center'} variant={'h2'}>
                        Մուտք
                    </DialogTitle>
                    <DialogContent>
                        <Box paddingTop={pxToRem(20)}>
                            <TextField
                                fullWidth
                                required
                                label="Մուտքային անուն"
                                type="email"
                                name="email"
                                aria-describedby="emailTextHelper"
                                inputProps={{
                                    'aria-label': 'email',
                                }}
                                onChange={(e) => {
                                    const { name, value } = e.target;
                                    setUser((prevState: UserRequest) => ({
                                        ...prevState,
                                        [name]: value,
                                    }));
                                }}
                            />

                            <TextField
                                sx={{ mt: pxToRem(20) }}
                                fullWidth
                                required
                                label="Գաղտնաբառ"
                                name="password"
                                type={showPass ? 'text' : 'password'}
                                aria-describedby="passwordTextHelper"
                                InputProps={{
                                    'aria-label': 'password',
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() =>
                                                    setShowPass(
                                                        (current) => !current,
                                                    )
                                                }
                                                onMouseDown={(e) =>
                                                    e.preventDefault()
                                                }
                                                edge="end"
                                            >
                                                {showPass ? (
                                                    <Eye />
                                                ) : (
                                                    <NounEye />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(e) => {
                                    const { name, value } = e.target;
                                    setUser((prevState: UserRequest) => ({
                                        ...prevState,
                                        [name]: value,
                                    }));
                                }}
                            />
                            <Box
                                display={'flex'}
                                justifyContent={'space-around'}
                                alignItems={'center'}
                                marginTop={pxToRem(24)}
                            >
                                <FormGroup
                                    aria-label="position"
                                    row
                                    sx={{ ml: pxToRem(8) }}
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                /*  checked={filterData.inspectionResult?.includes(
                                                        inspectionResult.id,
                                                    )}*/
                                                size={'small'}
                                                /*onChange={(e) => {
                                                        const { value, checked } =
                                                            e.target;
                                                        setFilterData((prevState) => {
                                                            return setInspectionResult(
                                                                prevState,
                                                                checked,
                                                                parseInt(value),
                                                            );
                                                        });
                                                    }}*/
                                            />
                                        }
                                        label={'Հիշել ինձ'}
                                    />
                                </FormGroup>
                                <Typography
                                    variant={'h6'}
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setOpenSecretingDialog(true);
                                        setOpenDialog(false);
                                    }}
                                >
                                    Վերականգնել Գաղտնաբարը
                                </Typography>
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ display: 'block' }}>
                        <Box display={'flex'} justifyContent={'end'}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSignIn}
                            >
                                Մուտք
                            </Button>
                        </Box>
                        <Box
                            marginTop={pxToRem(40)}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                        >
                            <Typography variant={'h6'} mr={pxToRem(8)}>
                                Անձնական էջի բացակայության դեպքում
                            </Typography>
                            <Typography
                                sx={{ cursor: 'pointer' }}
                                variant={'h6'}
                                color={colors.primary.main}
                                onClick={handelRegister}
                            >
                                Գրանցվել
                            </Typography>
                        </Box>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
};
export default Login;
