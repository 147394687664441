import { apiSlice } from '../api/api-slice';
import serializeObjectToURI from 'utils/serializer';
import {
    ViewedCasesListInfo,
    ViewedCasesListRequest,
} from 'types/viewed-cases-list/viewed-cases-list';

export const viewedCasesListApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getViewedCasesList: builder.query<
            ViewedCasesListInfo,
            ViewedCasesListRequest
        >({
            query: (viewedCasesListRequest: ViewedCasesListRequest) => ({
                url: serializeObjectToURI(
                    viewedCasesListRequest,
                    'app/case-view-history?',
                ),
                method: 'GET',
            }),
        }),
    }),
});

export const { useLazyGetViewedCasesListQuery } = viewedCasesListApi;
