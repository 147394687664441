import * as React from 'react';
import { Box, Link, Typography, useMediaQuery, useTheme } from '@mui/material';
import colors from 'assets/theme/base/colors';
import pxToRem from 'assets/theme/function/px-to-rem';
import { ReactComponent as LogoUniLex } from 'assets/images/header/logo-unilex.svg';
import { ReactComponent as FooterIcon } from 'assets/images/footer/footer-icon.svg';

const Footer: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <>
            <Box
                py={pxToRem(32)}
                px={
                    isMobile
                        ? pxToRem(24)
                        : isTablet
                          ? pxToRem(35)
                          : pxToRem(72)
                }
                minHeight={pxToRem(141)}
                bgcolor={colors.footer.background}
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <Box display={'grid'}>
                    <Link
                        variant={'h2'}
                        fontWeight={'bold'}
                        color={colors.white.main}
                        underline={'none'}
                        onClick={() => '/'}
                    >
                        <LogoUniLex />
                    </Link>

                    <Link
                        variant={isMobile ? 'h6' : 'h4'}
                        color={colors.white.main}
                        underline={'none'}
                        onClick={() => '/'}
                    >
                        Դատական Տեղեկատվական Համակարգ
                    </Link>
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                    <FooterIcon />
                    <Typography
                        ml={pxToRem(8)}
                        variant={isMobile ? 'h6' : 'h5'}
                        color={colors.white.main}
                    >
                        2024 DataLex. All rights reserved
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

export default Footer;
