import React from 'react';
import { Box } from '@mui/material';
import Header from 'component/header';
import Footer from 'component/footer';

interface LayoutProps {
    children: React.ReactNode;
    pageTitle: string;
}

const PublicLayout = (props: LayoutProps) => {
    const { children, pageTitle } = props;

    return (
        <Box>
            <Header pageTitle={pageTitle} />
            <Box>{children}</Box>
            <Footer />
        </Box>
    );
};

export default PublicLayout;
