import React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import RouterView from 'routes';
import theme from 'assets/theme';
import SnackbarAlert from 'component/snackbar-alert/snackbar-alert';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const App: React.FC = () => {
    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <SnackbarAlert />
                <RouterView />
            </ThemeProvider>
        </BrowserRouter>
    );
};

export default App;
