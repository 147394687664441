import React from 'react';
import {
    Box,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
} from '@mui/material';
import { RouteParams } from 'types/routes/route-params';
import routes from 'routes/routes';
import colors from 'assets/theme/base/colors';
import { useLocation, useNavigate } from 'react-router-dom';
import pxToRem from 'assets/theme/function/px-to-rem';

const CurrentUserMenu: React.FC = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        routePath: string,
    ) => {
        navigate(routePath);
    };
    return (
        <>
            <Box height={'65vh'} minHeight={'100%'} bgcolor={colors.info.main}>
                <List
                    component="nav"
                    aria-label="side bar menu"
                    sx={{ marginTop: pxToRem(30) }}
                >
                    <ListItem
                        sx={{ display: 'grid', justifyContent: 'stretch' }}
                    >
                        {routes.map(
                            (routeParam: RouteParams, index: number) =>
                                routeParam.rules?.private && (
                                    <Box key={index}>
                                        <ListItemButton
                                            sx={{ ml: pxToRem(32) }}
                                            selected={
                                                pathname === routeParam.path
                                            }
                                            onClick={(event) =>
                                                handleListItemClick(
                                                    event,
                                                    routeParam.path,
                                                )
                                            }
                                        >
                                            <ListItemText
                                                primary={routeParam.title}
                                            />
                                        </ListItemButton>
                                        <Divider />
                                    </Box>
                                ),
                        )}
                    </ListItem>
                </List>
            </Box>
        </>
    );
};

export default CurrentUserMenu;
