import typography from 'assets/theme/base/typography';
import borders from 'assets/theme/base/borders';
import pxToRem from 'assets/theme/function/px-to-rem';

const { size } = typography;
const { borderRadius } = borders;

const root = {
    height: pxToRem(44),
    borderRadius: borderRadius.md,
    padding: `${pxToRem(12)} ${pxToRem(24)}`,
    lineHeight: 'normal',
    boxShadow: 'none',
    '@media (max-width:740px)': {
        height: pxToRem(38),
    },

    '&:hover': {
        boxShadow: 'none',
    },

    '&:disabled': {
        pointerEvent: 'none',
        opacity: 0.65,
    },

    '& .material-icon, .material-icons-round, svg': {
        fontSize: size.xs,
    },

    '&:active, &:active:focus, &:active:hover': {
        opacity: 0.85,
    },
};

export default root;
