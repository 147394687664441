import colors from 'assets/theme/base/colors';

const radio = {
    styleOverrides: {
        root: {
            color: colors.info.main,
            checkedIcon: colors.info.main,
            '&.Mui-checked': {
                color: colors.text.secondary,
            },
        },
    },
};

export default radio;
